import styled from 'styled-components';

export const Container = styled.main`
`;

export const Content = styled.div`
    margin: 64px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
`;

export const PageTitle = styled.div`
    display: flex;

    place-content: center;
`;

export const DayContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
`;

export const Day = styled.div`
    border-radius: 3px;
    background: #28262E;
    border: 0px;
    padding: 0 16px;
    color: #FCFE38;
    font-weight: bold;

    margin: 0 10px 15px;

    align-items: center;
    text-align: center;

    :hover {
        cursor: pointer;
        background: #3E3B47;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    }

    > h2 {
        font-weight: 400;
        font-size: 18px;
        color: #FFF;
    }

    > h3 {
        font-weight: 400;
        font-size: 13px;
        color: #928D8D;
        margin-bottom: 3px;
    }

`;

export const Description = styled.div`
    border-radius: 3px;
    padding: 0 16px;
    color: #D1D1D1;


    margin-bottom: 10px;

    align-items: center;
    text-align: center;
    justify-content: center;

    display: flex;

    border-bottom: #4C4A52 solid 1px;

    h1 {
        font-weight: 300;
        font-size: 16px;
    }
`;

export const TitleClass = styled.h2`
    color: #D1D1D1;
    font-size: 18px;
    font-weight: bold;
    align-items: center;
    text-align: center;
    margin-right: 6px;
`;

export const ChangeClass = styled.div`
    border-radius: 3px;
    padding: 0 16px;
    color: #FFF;
    font-weight: bold;

    margin: 20px 10px 15px;

    align-items: center;
    text-align: center;

    border-top: #fff solid 4px;
    border-bottom: #fff solid 4px;

    > h2 {
        color: #8A8A8A;
        font-weight: 400;
        font-size: 18px;
    }

`;

export const ChangeClassDescription = styled.div`
    border-radius: 3px;
    padding: 0 16px;
    color: #D1D1D1;


    margin-bottom: 10px;

    align-items: center;
    text-align: center;
    justify-content: center;

    display: flex;

    h1 {
        font-weight: 300;
        font-size: 16px;
    }
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 20px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;
