import { createMuiTheme } from '@material-ui/core/styles';

const darkTheme = {
  type: 'dark',
  primary: {
    main: '#28262E',
  },
  secondary: {
    main: '#28262E',
  },
};

const lightTheme = {
  type: 'light',
  primary: {
    main: '#002F6A',
  },
  secondary: {
    main: '#363636',
  },
};

export const theme = (darkMode) => createMuiTheme({
  palette: darkTheme,
  typography: {
    h5: {
      // fontSize: '1.35rem',
    },
    subtitle1: {
      // fontSize: "1.15rem",
    },
  },
});
