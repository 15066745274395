/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, {
  useCallback, useState, useRef,
} from 'react';

import { zonedTimeToUtc } from 'date-fns-tz';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { FiFrown, FiArrowUp } from 'react-icons/fi';
import { useToast } from '../../../../hooks/toast';

import {
  Container, Content, PageTitle, SearchContainer, SearchMessage,
} from './styles';

import api from '../../../../services/api';
import Table from '../../../../components/Table';
import Select from '../../../../components/Select';

interface MemberList {
    id: string;
    name: string;
    date_birth: any;
    date_birth_order?: any;
    modality: {
        title: string;
    };
    class: {
        title: string;
    };
}


const Birthdays: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const [members, setMembers] = useState<MemberList[]>([]);
  const [monthPrint, setMonthPrint] = useState<string>();

  const handleSubmit = useCallback(async (data: any) => {
    const month = data.value;
    setMonthPrint(data.label);

    try {
      api.get('/members/birthdays', {
        params: {
          month_birth: month,
        },
      }).then((response) => {
        response.data.map((member: MemberList) => {
          const inicialDate = zonedTimeToUtc(member.date_birth, 'America/Sao_Paulo');
          member.date_birth_order = Number(format(inicialDate, 'dd'));
          member.date_birth = format(inicialDate, "dd'/'MM'");
        });
        setMembers(response.data.sort((a: any, b: any) => a.date_birth_order - b.date_birth_order));
      });
    } catch (err) {
      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'data' in err.response ? err.response.data.message : 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
      });
    }
  }, [addToast]);

  return (
    <Container>
      <PageTitle>
        <h1>Aniversariantes</h1>

        <Form
          ref={formRef}
          onSubmit={() => {}}
        >

          <Select
            placeholder="Mês..."
            name="month"
            options={[
              { value: '1', label: 'Janeiro' },
              { value: '2', label: 'Fevereiro' },
              { value: '3', label: 'Março' },
              { value: '4', label: 'Abril' },
              { value: '5', label: 'Maio' },
              { value: '6', label: 'Junho' },
              { value: '7', label: 'Julho' },
              { value: '8', label: 'Agosto' },
              { value: '9', label: 'Setembro' },
              { value: '10', label: 'Outubro' },
              { value: '11', label: 'Novembro' },
              { value: '12', label: 'Dezembro' },
            ]}
            onChange={(event: any) => { handleSubmit(event); }}
          />
        </Form>

      </PageTitle>

      <Content>
        {!monthPrint ? (
          <SearchContainer>
            <FiArrowUp />
            <SearchMessage>Primeiro, selecione o mês desejado</SearchMessage>
          </SearchContainer>
        )
          : (
            <></>
          )}

        {members.length !== 0 && monthPrint ? (
          <Table
            title={`Aniversariantes do mês de ${monthPrint}`}
            columns={[
              { title: 'NOME', field: 'name', cellStyle: { width: '90%' } },
              { title: 'DIA', field: 'date_birth', cellStyle: { width: '10%' } },
            ]}
            data={members}
            buttonExport
          />
        )
          : (
            <></>
          )}

        {members.length === 0 && monthPrint ? (
          <SearchContainer>
            <FiFrown />
            <SearchMessage>Nenhum aluno faz aniversário nesse mês</SearchMessage>
          </SearchContainer>
        )
          : (
            <></>
          )}

      </Content>
    </Container>
  );
};


export default Birthdays;
