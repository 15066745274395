import styled from 'styled-components';


export const Container = styled.main`
align-items: center;
display: flex;
flex-direction: column;

form {
        width: 100%;
        text-align: center;
        margin-top: 10px;

        @media (max-width: 500px) {
            width: 340px;

            h1 {
                margin-top: 10px;
                font-size: 25px;
            }
        }
    }
`;

export const Content = styled.div`
    width: 85%;
    margin: 0 290px 0 290px;

    @media (max-width: 500px) {
        width: 95%;
    }
`;

export const PageTitle = styled.div`
    display: flex;
    flex-direction: column;

    place-content: center;
    align-items: center;

    h2 {
        font-size: 20px;
        color: #FCFE38;
    }
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 20px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;
