/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';

import { Grid } from '@material-ui/core';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory, useLocation } from 'react-router-dom';

import InputLabel from '../../../components/InputLabel';
import InputLabelDate from '../../../components/InputLabelDate';
import Button from '../../../components/Button';
import Textarea from '../../../components/Textarea';


import { Container, Content, InputContainer } from './styles';

interface LocationState {
    wod_id: string;
    date: string;
    modality_id: string;
    modality: string;
    title: string;
    runs: string;
}

const DetailsWod: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location = useLocation<LocationState>();

  return (
    <Container>
      <Content>
        <h1>Detalhes do WOD</h1>
        <Form
          ref={formRef}
          onSubmit={() => {}}
          initialData={{
            date: location.state.date,
            title: location.state.title,
            runs: location.state.runs,
            modality_id: location.state.modality,
          }}
        >
          <Grid>
            <InputContainer>
              <InputLabelDate disabled autoComplete="off" id="1" name="date" label="Válido até" type="date" />
            </InputContainer>

            <InputContainer>
              <InputLabel disabled autoComplete="off" id="1" name="modality_id" label="Modalidade" style={{ color: '#FCFE38' }} />
            </InputContainer>

            <InputContainer>
              <InputLabel disabled autoComplete="off" id="1" name="title" label="Título" />
            </InputContainer>

            <InputContainer>
              <Textarea disabled autoComplete="off" id="1" name="runs" label="Exercícios" />
            </InputContainer>
          </Grid>
        </Form>
      </Content>
    </Container>
  );
};

export default DetailsWod;
