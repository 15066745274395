import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;

    // Regula as propriedades do container principal do Select
    .select {
      width: 100%;
      /* border: 1px solid #585858; */

        // Coloca uma borda no container quando o Select apresenta erro (não preenchimento)
        &-error {
          border: 2px solid #c53030;
        }

        // Tira a borda interna do Select
        .css-yk16xz-control {
          border: none;
          background: #232129;
        }

        // Tira a borda azul do Select, quando ele é selecionado
        .css-1pahdxg-control {
            border: none;
            box-shadow: none;
            background: #232129;
        }

        // Não permite que nada sobreponha a lista de opções aberta
        .css-26l3qy-menu {
            z-index: 11;
            background: #232129;
            font-weight: 500;
            color: #999;
        }

        // Altera a cor da fonte do item que foi selecionado
        .css-1uccc91-singleValue {
            color: #FFF;
        }

        .css-b8ldur-Input {
            color: #FFF;
        }
    }
`;
