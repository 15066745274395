import styled, { css } from 'styled-components';

import {
  LocationOn, Home, Event, Schedule, GpsFixed, Groups, AttachMoney, FitnessCenter, Label, Check, HourglassBottom, Cake, ThumbsUpDown,
} from '../../styles/Icons';

const iconsCSS = css`
      flex-shrink: 0;
      width: 31px;
      height: 31px;

      color: #FFF;

      cursor: pointer;

      &:hover,
      &.active {
          fill: #0B7FE3;
      }
  `;

export const HomeIcon = styled(Home)`${iconsCSS}`;
export const CheckinIcon = styled(LocationOn)`${iconsCSS}`;
export const WodIcon = styled(FitnessCenter)`${iconsCSS}`;
export const TimeBoardIcon = styled(Schedule)`${iconsCSS}`;
export const PlansIcon = styled(AttachMoney)`${iconsCSS}`;
export const EventIcon = styled(Event)`${iconsCSS}`;
export const WoWeAreIcon = styled(Groups)`${iconsCSS}`;
export const MissionIcon = styled(GpsFixed)`${iconsCSS}`;

export const ClassIcon = styled(Label)`${iconsCSS}`;
export const EnrolledMemberIcon = styled(Check)`${iconsCSS}`;
export const WaitingListIcon = styled(HourglassBottom)`${iconsCSS}`;
export const ExperimentalMemberIcon = styled(ThumbsUpDown)`${iconsCSS}`;
export const BirthdayIcon = styled(Cake)`${iconsCSS}`;

export const Container = styled.div`
    height: 100vh;
`;

// Envolverá
export const Content = styled.div`
    display: flex;
`;

// Conteúdo da página que será apresentada
export const Page = styled.div`
    margin: 0 auto;
    flex: 1;
    align-items: center;
    flex-direction: column;
`;

// Conteúdo da página que será apresentada
export const Footer = styled.div`
    background: #28262E;

    position: fixed;
    bottom: 0;

    left: 0;
    z-index: 2;

    width: 100%;

    border-top: #4C4A52 solid 1px;

    display: flex;
    justify-content: space-between;
    padding: 8px min(46px, max(10vh, 10px));

    @media (min-width: 500px) {
        display: none;
    }
`;
