import React, {
  useState, useCallback, useEffect, forwardRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {
  Container, Content, Description, Day, Days, TitleClass, DayContainer,
} from './styles';


import api from '../../../../../services/api';

import { useToast } from '../../../../../hooks/toast';

interface ClassList {
    modalityId: string;
    modalityName: string;
}

interface Class {
    id: string;
    title: string;
    description: string;
}

const DashboardClassesList = forwardRef(
  // ATIVAR MÉTODO FILHO: passo 7 (fazer o recebimento dessa referência)
  (props: ClassList) => {
    const { addToast } = useToast();
    const history = useHistory();

    const [classes, setClasses] = useState<Class[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const loadPanel = useCallback(() => {
      try {
        api.get(`/class/${props.modalityId}`).then((response) => {
          setClasses(response.data);
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ;(',
          description: 'Ocorreu um erro ao carregar a lista de turmas. Por favor, saia da página e retorne para uma nova tentativa.',
        });
      }
    }, [addToast, props.modalityId]);

    useEffect(() => {
      loadPanel();
    }, [loadPanel]);

    const openClassMembers = useCallback((className, classId) => {
      localStorage.setItem('className', className);
      localStorage.setItem('classId', classId);
      localStorage.setItem('modalityName', props.modalityName);
      localStorage.setItem('modalityId', props.modalityId);

      history.push('/members-by-class');
    }, [history, props.modalityName, props.modalityId]);

    return (
      <Container>
        <Content>
          <DayContainer>
            <Day>
              <h1>{props.modalityName}</h1>
            </Day>

            { isLoading
              ? <ScaleLoader loading={isLoading} color="#FFFFFF" />
              : (
                <>
                  {classes.map((team) => (
                    <Description>
                      <TitleClass
                        onClick={() => { openClassMembers(team.title, team.id); }}
                      >
                        {team.title}
                      </TitleClass>

                      <Days>
                        {team.description}
                      </Days>
                    </Description>
                  ))}
                </>
              )}

          </DayContainer>
        </Content>
      </Container>
    );
  },
);


export default DashboardClassesList;
