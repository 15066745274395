import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
`;

export const Content = styled.main`
    display: flex;
    flex-direction: column;
    margin: 64px auto;
    align-items: center;
    justify-content: center;
    text-align: center;

    svg {
        width: 90px;
        height: 90px;
        color: #00BB29;
        margin-bottom: 23px;
    }

    h1 {
        font-size: 35px;
    }

    h2 {
        font-size: 18px;
        font-weight: 400;
        padding: 33px;
    }

    h3 {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 50px;
        color: #c5c5c5;
    }

    a {
        text-decoration: none;
        color: #FCFE38;
    }
`;

const Option = styled.div`
    background: rgba(230, 232, 74, 0.3);
    color: #FCFE38;
    height: 56px;
    border-radius: 3px;
    border: 0;
    padding: 0 16px;
    width: 200px;
    font-weight: bold;
    transition: background-color 0.2s;

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;

    &:hover {
        cursor: pointer;
    }
`;

export const OptionSuccess = styled(Option)`
    border: 1px dotted #FCFE38;
`;

export const OptionFail = styled(Option)`
`;
