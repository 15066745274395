/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';

import { FaTrash, FaPlus, FaInfo } from 'react-icons/fa';

import {
  Tab, Tabs, Typography, Grid, Box,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import { v4 as uuid } from 'uuid';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';

import { zonedTimeToUtc } from 'date-fns-tz';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import api from '../../../../services/api';

import DialogBox from '../../../../components/DialogBox';
import InputLabel from '../../../../components/InputLabel';
import InputLabelDate from '../../../../components/InputLabelDate';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';

import {
  Container, Content, ContentForm, InputContainer, ActionsContainer, ActionsBox, ActionsCircle,
} from './styles';

  interface SignUpFormData {
      name: string;
      date_birth: string;
      cpf: string;
      email: string;
  }

  interface MyModalities {
    id: string;
    due_date?: string;
    due_date_formatted?: string;

    MODALITY: {
      id: string;
      title?: string;
    };

    CLASS: {
      id: string;
      title?: string;
    };
}

  interface MyModalitiesFormData {
    user_id: string;
    modality_id: string;
    class_id: string;
    due_date?: string;
  }

  interface EditMyModalitiesFormData {
    id: string;
    user_id: string;
    modality_id: string;
    class_id: string;
    due_date?: string;
  }

  interface Modalities {
      id: string;
      title: string;
  }

  interface ClassTeam {
      id: string;
      title: string;
  }

  interface LocationState {
    user_id: string;
    name: string;
    date_birth: string;
    cpf: string;
    email: string;
    due_date: string;
}

function TabPanel(props: any): any {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any): any {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const UpdateMember: React.FC = () => {
  const formMainRef = useRef<FormHandles>(null);
  const formModalityRef = useRef<FormHandles>(null);
  const formEditModalityRef = useRef<FormHandles>(null);

  const [value, setValue] = useState(0);

  const { addToast } = useToast();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const [memberInfo, setMemberInfo] = useState<SignUpFormData>();

  const [modalities, setModalities] = useState<Modalities[]>([]);
  const [classTeam, setClassTeam] = useState<ClassTeam[]>([]);

  const [selectModality, setSelectModality] = useState<Modalities>();
  const [selectClassTeam, setSelectClassTeam] = useState<ClassTeam>();

  const [myModalities, setMyModalities] = useState<MyModalities[]>([]);

  const [editMyModalities, setEditMyModalities] = useState<any>();

  const [boxIsOpen, setBoxIsOpen] = useState(false);
  const [editBoxIsOpen, setEditBoxIsOpen] = useState(false);

  useEffect(() => {
    api.get('/modality').then((response) => {
      const data: any = [];
      response.data.map((item: any) => {
        data.push({ value: item.id, label: item.title });
      });
      setModalities(data);
    });
  }, [selectModality]);

  useEffect(() => {
    setMemberInfo({
      name: location.state.name,
      cpf: location.state.cpf,
      email: location.state.email,
      date_birth: location.state.date_birth,
    });
  }, []);

  useEffect(() => {
    try {
      api.get(`/myModalities/${location.state.user_id}`).then((response) => {
        setMyModalities(response.data);
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao carregar as modalidades desse aluno.',
      });
    }
  }, [location.state.user_id, addToast]);

  const onChangeSelectModality = useCallback(async (event: any) => {
    setSelectModality({ id: event.value, title: event.label });

    api.get(`/class/${event.value}`).then((response) => {
      const data: any = [];
      response.data.map((item: any) => {
        data.push({ value: item.id, label: item.title });
      });
      setClassTeam(data);
    });
  }, []);

  const handleSaveBasicInformation = useCallback(async (nextTab) => {
    try {
      if (nextTab === 1) {
              formMainRef.current?.setErrors({});

              const allData = {
                name: formMainRef.current?.getFieldValue('name'), date_birth: formMainRef.current?.getFieldValue('date_birth'), cpf: formMainRef.current?.getFieldValue('cpf'), email: formMainRef.current?.getFieldValue('email'),
              };

              const schema = Yup.object().shape({
                name: Yup.string().required('Obrigatório'),
                date_birth: Yup.string().required('Obrigatório'),
                cpf: Yup.string().required('Obrigatório'),
                email: Yup.string().required('Obrigatório').email('E-mail inválido'),
              });

              await schema.validate(allData, { abortEarly: false });

              setValue(nextTab);
              setMemberInfo(allData);
      } else {
        setValue(nextTab);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

            formMainRef.current?.setErrors(errors);
      }
    }
  }, []);

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
            formMainRef.current?.setErrors({});

            const schema = Yup.object().shape({
              name: Yup.string().required('Obrigatório'),
              date_birth: Yup.date().required('Obrigatório'),
              cpf: Yup.number().required('Obrigatório'),
              email: Yup.string().required('Obrigatório').email('E-mail inválido'),
            });

            if (myModalities.length === 0) {
              addToast({
                type: 'info',
                title: 'Adicione uma modalidade e uma turma para prosseguir',
              });

              setMemberInfo(data);
              setValue(1);

              return;
            }

            await schema.validate(data, {
              abortEarly: false,
            });

            const {
              name, cpf, email, date_birth,
            } = data;

            const birthday = zonedTimeToUtc(date_birth, 'America/Sao_Paulo');
            const month_birth = format(birthday, 'MM');

            // Aqui nós transformamos o modelo de dados das modalidades no padrão configurado no banco de dados e no mapeamento de modalidades do aluno
            const convertModalities: any = [];
            myModalities.map((item) => {
              const transferValue = { modality_id: item.MODALITY.id, modality: item.MODALITY.title };
              convertModalities.push(transferValue);
            });

            // Aqui nós transformamos o array em string (modalidades) para salvar as modalidades daquele aluno que serão utilizadas na listagem do check-in
            const modality = JSON.stringify(convertModalities, ['modality_id', 'modality']);

            const formData = {
              user_id: location.state.user_id,
              name,
              date_birth,
              month_birth,
              cpf,
              email,
              due_date: '',
              modalities: modality,
            };

            await api.put('/members', formData);
            await api.delete(`/myModalities/${location.state.user_id}`);

            // // Faz o tratamento do array de MINHAS MODALIDADES para salvar no banco
            const myModalitiesFormData: MyModalitiesFormData[] = [];
            myModalities.map((item) => {
              const insert = {
                user_id: location.state.user_id, modality_id: item.MODALITY.id, class_id: item.CLASS.id, due_date: item.due_date,
              };

              myModalitiesFormData.push(insert);
            });

            await api.post('/myModalities', myModalitiesFormData);

            history.push('/dashboard-members');

            addToast({
              type: 'sucess',
              title: 'Aluno editado com sucesso',
            });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

            formMainRef.current?.setErrors(errors);

            return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'data' in err.response ? err.response.data.message : 'Ocorreu um erro ao realizar essa edição. Tenta de novo, por favor.',
        // description: 'Ocorreu um erro ao realizar essa edição. Tenta de novo, por favor.',
      });
    }
  }, [addToast, history, myModalities, location.state.user_id]);

  const handleAddModality = useCallback(async (data: MyModalitiesFormData) => {
    try {
        formModalityRef.current?.setErrors({});

        const schema = Yup.object().shape({
          class_id: Yup.string().required('Obrigatório'),
          modality_id: Yup.string().required('Obrigatório'),
          due_date: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const { class_id, modality_id, due_date } = data;

        setMyModalities([...myModalities, {
          id: uuid(), MODALITY: { id: modality_id, title: selectModality?.title }, CLASS: { id: class_id, title: selectClassTeam?.title }, due_date,
        }]);

        setSelectModality({ id: '', title: '' });
        setSelectClassTeam({ id: '', title: '' });
        setBoxIsOpen(false);

        addToast({
          type: 'sucess',
          title: 'Sucesso!',
          description: 'Modalidade cadastrada com sucesso',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

            formModalityRef.current?.setErrors(errors);

            return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao adicionar essa modalidade. Tenta de novo, por favor.',
      });
    }
  }, [myModalities, addToast, selectModality, selectClassTeam]);

  const handleRemoveModality = useCallback((id) => {
    const array = [...myModalities];

    const index = array.map((item) => item.id).indexOf(id);
    array.splice(index, 1);

    setMyModalities(array);
  }, [myModalities]);

  const handleComposeEditModality = useCallback(async (id: string, idModality: string, titleModality: string | undefined, idClass: string, titleClass: string | undefined, due_date: string | undefined) => {
    setEditMyModalities({
      id, idModality, titleModality, idClass, titleClass, due_date,
    });

    setSelectModality({ id: idModality, title: String(titleModality) });
    setSelectClassTeam({ id: idClass, title: String(titleClass) });

    api.get(`/class/${idModality}`).then((response) => {
      const data: any = [];
      response.data.map((item: any) => {
        data.push({ value: item.id, label: item.title });
      });
      setClassTeam(data);
    });

    setEditBoxIsOpen(true);
  }, []);

  const handleEditModality = useCallback(async (data: EditMyModalitiesFormData) => {
    try {
      const {
        id, class_id, modality_id, due_date,
      } = data;

      const array = [...myModalities];

      const index = array.map((item) => item.id).indexOf(id);
      array.splice(index, 1);

      setMyModalities([...array, {
        id: uuid(), MODALITY: { id: modality_id, title: selectModality?.title }, CLASS: { id: class_id, title: selectClassTeam?.title }, due_date,
      }]);

      setEditBoxIsOpen(false);

      addToast({
        type: 'sucess',
        title: 'Sucesso!',
        description: 'Modalidade editada com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao editar essa modalidade. Tenta de novo, por favor.',
      });
    }
  }, [myModalities, addToast, selectModality, selectClassTeam]);

  return (
    <Container>
      <Content>
        <h1>Editar aluno</h1>
        <Form
          ref={formMainRef}
          onSubmit={handleSubmit}
          initialData={{
            name: memberInfo ? memberInfo.name : '',
            date_birth: memberInfo?.date_birth ? memberInfo?.date_birth : location.state.date_birth,
            cpf: memberInfo ? memberInfo.cpf : '',
            email: memberInfo ? memberInfo.email : '',
          }}
        >
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center',
          }}
          >
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={value}
              onChange={(event: any, nextTab: any): void => { handleSaveBasicInformation(nextTab); }}
              TabIndicatorProps={{
                style: { background: '#FCFE38' },
              }}
            >
              <Tab label="Informações" {...a11yProps(0)} style={{ outline: 'none' }} />
              <Tab label="Modalidades" {...a11yProps(1)} style={{ outline: 'none' }} />
            </Tabs>
            <div style={{ width: '100%' }}>
              <TabPanel value={value} index={0}>
                <Grid>
                  <InputContainer>
                    <InputLabel autoComplete="off" id="1" name="name" label="Nome" />
                  </InputContainer>

                  <InputContainer>
                    <InputLabelDate autoComplete="off" id="1" name="date_birth" label="Nascimento" type="date" />
                  </InputContainer>

                  <InputContainer>
                    <InputLabel autoComplete="off" id="1" name="cpf" label="CPF" type="number" />
                  </InputContainer>

                  <InputContainer>
                    <InputLabel autoComplete="off" id="1" name="email" label="E-mail" type="email" />
                  </InputContainer>
                  <Button type="submit">Gravar</Button>
                </Grid>

              </TabPanel>
              <TabPanel value={value} index={1}>
                <ActionsContainer>
                  <ActionsBox>
                    <ActionsCircle
                      onClick={() => setBoxIsOpen(true)}
                    >
                      <FaPlus />
                    </ActionsCircle>
                  </ActionsBox>
                </ActionsContainer>
                {myModalities.map((item) => (
                  <>
                    <InputContainer>
                      <InputLabel autoComplete="off" id="1" disabled name="" label="Modalidade" value={item.MODALITY.title} style={{ color: '#FCFE38' }} />
                    </InputContainer>

                    <InputContainer>
                      <InputLabel autoComplete="off" id="1" disabled name="" label="Turma" value={item.CLASS.title} />
                    </InputContainer>

                    <InputContainer>
                      <InputLabel autoComplete="off" id="1" disabled name="" label="Vencimento" type="date" value={item.due_date} />
                    </InputContainer>

                    <ActionsContainer>
                      <ActionsBox>
                        <ActionsCircle
                        //   onClick={() => setEditBoxIsOpen(true)}
                          onClick={() => handleComposeEditModality(item.id, item.MODALITY.id, item.MODALITY.title, item.CLASS.id, item.CLASS.title, item.due_date)}
                          style={{ background: '#0396C4' }}
                        >
                          <FaInfo />
                        </ActionsCircle>

                        <ActionsCircle
                          onClick={() => { handleRemoveModality(item.id); }}
                        >
                          <FaTrash />
                        </ActionsCircle>
                      </ActionsBox>
                    </ActionsContainer>
                  </>
                ))}

                {/* <Button onClick={() => setBoxIsOpen(true)}>Nova modalidade</Button> */}
              </TabPanel>
            </div>
          </div>
        </Form>
      </Content>

      {/* Formulário de inclusão de nova modalidade */}
      <DialogBox
        open={boxIsOpen}
        onClose={(): void => setBoxIsOpen(false)}
      >
        <ContentForm>
          <Form
            ref={formModalityRef}
            onSubmit={handleAddModality}
            initialData={{
              due_date: format(new Date(), 'yyyy-MM-dd'),
            }}
          >
            <h1 style={{ marginBottom: 20 }}>Nova modalidade</h1>
            <Grid>
              <InputContainer>
                <Select
                  placeholder="Modalidade..."
                  name="modality_id"
                  options={modalities}
                  onChange={(event: any) => { onChangeSelectModality(event); }}
                />
              </InputContainer>

              <InputContainer>
                <Select
                  placeholder="Turma..."
                  name="class_id"
                  options={classTeam}
                  onChange={(event: any) => { setSelectClassTeam({ id: event.value, title: event.label }); }}
                />
              </InputContainer>

              <InputContainer>
                <InputLabelDate autoComplete="off" id="1" name="due_date" label="Vencimento" type="date" />
              </InputContainer>

              <Button type="submit">Adicionar</Button>
            </Grid>
          </Form>
        </ContentForm>
      </DialogBox>

      {/* Formulário de edição de nova modalidade */}
      <DialogBox
        open={editBoxIsOpen}
        onClose={(): void => setEditBoxIsOpen(false)}
      >
        <ContentForm>
          <Form
            ref={formEditModalityRef}
            onSubmit={handleEditModality}
            initialData={{
              modality_id: { value: editMyModalities?.idModality, label: editMyModalities?.titleModality },
              class_id: { value: editMyModalities?.idClass, label: editMyModalities?.titleClass },
              due_date: editMyModalities?.due_date,
              id: editMyModalities?.id,
            }}
          >
            <h1 style={{ marginBottom: 20 }}>Editar modalidade</h1>
            <Grid>
              <InputContainer
                style={{ display: 'none' }}
              >
                <Select
                  placeholder="Modalidade..."
                  name="modality_id"
                  options={modalities}
                  onChange={(event: any) => { onChangeSelectModality(event); }}
                />
              </InputContainer>

              <InputContainer>
                <Select
                  placeholder="Turma..."
                  name="class_id"
                  options={classTeam}
                  onChange={(event: any) => { setSelectClassTeam({ id: event.value, title: event.label }); }}
                />
              </InputContainer>

              <InputContainer>
                <InputLabelDate autoComplete="off" id="1" name="due_date" label="Vencimento" type="date" />
              </InputContainer>

              <InputContainer
                style={{ display: 'none' }}
              >
                <InputLabelDate autoComplete="off" id="1" name="id" label="ID" />
              </InputContainer>

              <Button type="submit">Editar</Button>
            </Grid>
          </Form>
        </ContentForm>
      </DialogBox>
    </Container>
  );
};

export default UpdateMember;
