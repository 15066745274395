import styled from 'styled-components';
import { shade } from 'polished';


export const Container = styled.main`
display: flex;
align-items: center;
flex-direction: column;
`;

export const Content = styled.div`
    width: 85%;
    margin: 30px 290px;

    @media (max-width: 500px) {
        width: 95%;
    }
`;

export const PageTitle = styled.div`
    display: flex;
    place-content: center;
`;

// *** GRID ***
export const SearchButton = styled.button`
    place-content: center;
    width: 57px;
    height: 57px;
    background: #131216;
    border: 0;
    border-radius: 0 7px 7px 0;
    @media (max-width: 500px) {
        height: 50px;
    }
`;

export const AddButton = styled.button`
    place-content: center;
    width: 57px;
    height: 57px;
    background: #28262E;
    border: 0;
    border-radius: 10px;
    margin-top: 15px;
    @media (max-width: 500px) {
        height: 50px;
        /* padding: 0 10px 0 10px; */
    }
`;

export const ContainerHeaderGrid = styled.div`
    width: 95%;
    display: flex;
    border-radius: 2px;
    & + div {
        margin-top: 16px;
    }
    /* @media (max-width: 500px) {
        width: 50%;
    } */
`;

export const HeaderGrid = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const ItemsContainer = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    svg {
        width: 40px;
        height: 40px;
        color: #FCFE38;
        margin-left: 5px;
    }
`;

export const HeaderName = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 95%;
    text-align: center;
    margin-left: 20px;
    @media (max-width: 500px) {
        width: 95%;
    }
    /* background: #000; */
`;

export const HeaderModality = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 300px;
    text-align: center;
    @media (max-width: 500px) {
        width: 100px;
    }
    /* background: #000; */
`;

export const HeaderDueDate = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 95%;
    text-align: center;
    @media (max-width: 500px) {
        width: 95%;
    }
    /* background: #000; */
`;

export const MembersList = styled.div`
    width: 95%;
    display: flex;
    border: #4C4A52 solid 1px;
    :last-of-type {
        border-radius: 0 0 10px 10px;
    }
    &:nth-child(2) {
        border-radius: 10px 10px 0 0;
    }
`;

export const Grid = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    :hover {
        cursor: pointer;
        background: #232129;
    }
`;

export const MemberName = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 100%;
    min-height: 45px; // Controla a altura de toda a linha
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    overflow: hidden;
    @media (max-width: 500px) {
        width: 95%;
        font-size: 12px;
        min-height: 30px; // Controla a altura de toda a linha
    }
`;

export const MemberModality = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 500px) {
        width: 100px;
        font-size: 10px;
    }
    /* background: #000; */
`;

export const MemberDueDate = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 500px) {
        width: 95%;
        font-size: 12px;
    }
    /* background: #000; */
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 25px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;
