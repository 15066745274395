import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import {
  Container, Content, Option, PageTitle,
} from './styles';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Modalities } from './modalities';

const RecurringCredit: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();

  function handleLinkToVindi(url: string) {
    window.open(url, '_blank');
  }

  return (
    <Container>
      <PageTitle>
        <h1>Contratar um Plano</h1>
        <h2>Aqui você pode contratar um plano com o Crédito Recorrente</h2>
      </PageTitle>

      <Content>
        {Modalities.map((item) => (
          <Option
            onClick={() => handleLinkToVindi(item.url)}
          >
            {item.description}

          </Option>
        ))}
      </Content>
    </Container>
  );
};


export default RecurringCredit;
