import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div``;

export const PageTitle = styled.div`
    display: flex;
    place-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;



    form {
        margin-top: 40px;
        width: 340px;
        text-align: center;
        display: flex;
        flex-direction: column;

        @media (max-width: 500px) {
            margin: 0;
            width: 250px;
    }

        a {
            color: #F4EDE8;
            display: block;
            margin-top: 14px;
            text-decoration: none;
            transition: color 0.2s;

            &:hover{
                color: ${shade(0.2, '#F4EDE8')};
            }
        }

        input[name=old_password ] {
            margin-top: 24px;
        }
    }
`;

export const AvatarInput = styled.div`
    margin-bottom: 32px;
    position: relative;
    align-self: center;

    img {
        width: 186px;
        height: 186px;
        border-radius: 50%;
        object-fit: cover;
    }

    label {
        position: absolute;
        width: 48px;
        height: 48px;
        background: #FF9000;
        border-radius: 50%;
        right: 0;
        bottom: 0;
        border: 0;
        cursor: pointer;
        transition: background-color 0.2;

        display: flex;
        align-items: center;
        justify-content: center;

        input {
            display: none;
        }

        svg {
            width: 20px;
            height: 20px;
            color: #312e38;
        }

        &:hover {
            background: ${shade(0.2, '#FF9000')};
        }
    }
`;
