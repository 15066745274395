import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';

import { FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import MaterialTable from 'material-table';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useToast } from '../../../../hooks/toast';

import {
  Container, Content, PageTitle, AddButton,
} from './styles';

import api from '../../../../services/api';

import Table from '../../../../components/TableMember';

  interface FindMemberFormData {
      member_info: string;
  }

  interface MemberList {
      id: string;
      name: string;
      date_birth: string;
      cpf: string;
      email: string;
  }


const DashboardMembers: React.FC = () => {
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [members, setMembers] = useState<MemberList[]>([]);
  const [foundMembers, setFoundMembers] = useState<MemberList[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      api.get('/members').then((response) => {
        setMembers(response.data);
        setFoundMembers(response.data);
      });

      setIsLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Ocorreu um erro ao carregar a lista de alunos. Por favor, saia da página e retorne para uma nova tentativa.',
      });
    }
  }, [addToast]);

  const handleSubmit = useCallback(async (data: FindMemberFormData) => {
    try {
          formRef.current?.setErrors({});

          const {
            member_info,
          } = data;

          if (!member_info) {
            api.get('/members').then((response) => {
              setMembers(response.data);
            });
          } else {
            await api.get('members/input', {
              params: {
                input: member_info,
              },
            }).then((response) => {
              setMembers(response.data);
            });
          }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Deu um erro na hora de buscar esse aluno em específico. Tenta de novo, por favcor.',
      });
    }
  }, [addToast]);

  const handleDelete = useCallback(async (id: string) => {
    try {
      await api.delete(`users/${id}`);

      api.get('/members').then((response) => {
        setMembers(response.data);
      });

      addToast({
        type: 'sucess',
        title: 'Aluno removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Deu um erro quando tentei excluir esse aluno. Tenta de novo, por favor.',
      });
    }
  }, [addToast]);

  const handleFind = useCallback((data) => {
    // Cria uma expressão Regex com a variável provider
    const regex = new RegExp(data, 'i');
    // Com essa expressão criada, filtrei o array (FILTER) testando (test) a condição 'company_name'
    const member = foundMembers.filter((item) => regex.test(item.name));
    // setFoundMembers(member);
    setMembers(member);
  }, [foundMembers]);

  return (
    <Container>
      <PageTitle>
        <h1>Alunos</h1>
      </PageTitle>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <AddButton>
          <FiPlus
            onClick={() => history.push('/new-member')}
            style={{
              width: 40, height: 40, color: '#FCFE38',
            }}
          />
        </AddButton>
      </Form>

      <Content>
        <Table
          columns={[
            { title: 'NOME', field: 'name' },
          ]}
          data={members}
          loader={isLoading}
          actions={[
            {
              icon: 'create',
              iconProps: { style: { color: '#8F8F8F' } },
              tooltip: 'Editar',
              onClick: (event: any, row: MemberList): void => {
                history.push({
                  pathname: '/update-member',
                  state: {
                    user_id: row.id, name: row.name, date_birth: row.date_birth, cpf: row.cpf, email: row.email,
                  },
                });
              },
            },
            {
              icon: 'delete',
              iconProps: { style: { color: '#FCFE38' } },
              tooltip: 'Excluir',
              onClick: (event: any, row: MemberList): void => {
                handleDelete(row.id);
              },
            },
          ]}
        />
      </Content>
    </Container>
  );
};


export default DashboardMembers;
