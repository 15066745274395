import styled from 'styled-components';

interface ImageProps {
 imagePath: string;
}

export const Container = styled.div``;

export const PageTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;

    h2 {
        color: #c5c5c5;
        font-size: 17px;
        font-weight: 400;
        margin-top: 10px;
    }
`;

export const Content = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 10px;

 flex-wrap: wrap;
`;

export const DisplayContainer = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 150px;
 height: 190px;
 margin-bottom: 50px;
 margin: 10px 20px;
`;

export const Banner = styled.a<ImageProps>`
 width: 156px;
 height: 156px;
 background: url(${(props) => props.imagePath}) no-repeat center;
 background-color: #fff;
 border-radius: 50%;
 border: 6px solid #232129;
`;

export const Label = styled.div`
 display: flex;
 width: 70%;
 height: 35px;
 background: #232129;
 border-radius: 6px;
 align-items: center;
 justify-content: center;
 padding: 0.1rem;
 margin-top: -5px;

 h1 {
   font-size: 1.2rem;
   color: #c40304;
 }
`;
