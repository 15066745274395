import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.main``;

export const Content = styled.div`
    margin: 64px auto;
    /* display: flex; */

    place-content: center;

    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    display: flex;

    place-content: center;
`;

export const PlanContainer = styled.div`
   display: flex;
   flex-direction: column;

`;

export const Plan = styled.div`
    border-radius: 3px;
    background: #28262E;
    border: 0px;
    padding: 0 16px;
    color: #FCFE38;
    font-weight: bold;

    margin: 0 10px 15px;

    align-items: center;
    text-align: center;

    > h2 {
        font-weight: 400;
        font-size: 18px;
    }

    > h3 {
        font-weight: 400;
        font-size: 13px;
        color: #928D8D;
    }

`;

export const Description = styled.div`
    border-radius: 3px;
    padding: 0 16px;
    color: #D1D1D1;


    margin-bottom: 10px;

    align-items: center;
    text-align: center;
    justify-content: center;

    display: flex;

    border-bottom: #4C4A52 solid 1px;

    h1 {
        font-weight: 300;
        font-size: 16px;
    }
`;

export const TitlePlan = styled.h2`
    color: #D1D1D1;
    font-size: 18px;
    font-weight: bold;
    align-items: center;
    text-align: center;
    margin-right: 6px;
`;

export const Payment = styled.div`
    border-radius: 3px;
    padding: 0 16px;
    color: #FFF;
    font-weight: bold;

    margin: 20px 10px 15px;

    align-items: center;
    text-align: center;

    border-top: #fff solid 4px;
    border-bottom: #fff solid 4px;

    > h2 {
        color: #8A8A8A;
        font-weight: 400;
        font-size: 18px;
    }

`;

export const PaymentDescription = styled.div`
    border-radius: 3px;
    padding: 0 16px;
    color: #D1D1D1;


    margin-bottom: 10px;

    align-items: center;
    text-align: center;
    justify-content: center;

    /* display: flex; */

    h1 {
        font-weight: 300;
        font-size: 16px;
    }
`;
