interface PartnersProps {
    id: number;
    partner: string;
    discountAmount: string;
    imagePath: string;
    socialMediaLink: string;
   }

export const partners: PartnersProps[] = [
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/agropesc.png',
    socialMediaLink: 'https://www.instagram.com/agropesc/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/biffy.png',
    socialMediaLink: 'https://www.instagram.com/emporiobiffyhouse/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '8% OFF',
    imagePath: '../../../assets/partners/bonetts.png',
    socialMediaLink: 'https://www.instagram.com/bonettspizzaeburger/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '20% OFF',
    imagePath: '../../../assets/partners/cleitotica.png',
    socialMediaLink: 'https://www.instagram.com/cleitootica/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/dinners.png',
    socialMediaLink: 'https://www.instagram.com/dinnersburger/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/fernanda-forato.png',
    socialMediaLink: 'https://www.instagram.com/ferforato_nutri/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/gauslab.png',
    socialMediaLink: 'https://www.instagram.com/gauslab/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/lassie.png',
    socialMediaLink: 'https://www.instagram.com/clinicalassie/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/lion.png',
    socialMediaLink: 'https://www.instagram.com/lionbar_/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/marmita-fit.png',
    socialMediaLink: 'https://www.instagram.com/marmitaffit/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '15% OFF',
    imagePath: '../../../assets/partners/mateus-poli.png',
    socialMediaLink: 'https://www.instagram.com/dr.mateuspoli/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '20% OFF',
    imagePath: '../../../assets/partners/nova-era.png',
    socialMediaLink: 'https://www.instagram.com/nova.eracontabilidade/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '20% OFF',
    imagePath: '../../../assets/partners/sandra-russo.png',
    socialMediaLink: 'https://www.instagram.com/srussobertoni/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/petshop-amigao.png',
    socialMediaLink: 'https://www.instagram.com/petshopamigaosocorro/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/biotech.png',
    socialMediaLink: 'https://www.instagram.com/biotechdd/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/drogaria-total.png',
    socialMediaLink: 'https://www.instagram.com/drogariatotal_socorro/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/moraes-vedovotto.png',
    socialMediaLink: 'https://www.instagram.com/moraesevedovottocalhas/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/delicias-da-mah.png',
    socialMediaLink: 'https://www.instagram.com/deliciasdamah_/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/privatte.png',
    socialMediaLink: 'https://www.instagram.com/privatteodontologia/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/cell-center.png',
    socialMediaLink: 'https://www.instagram.com/cellcenterlojaoficial/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/as-meninas.png',
    socialMediaLink: 'https://www.instagram.com/as.meninas.salao/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/barbearia-navalha-preto-no-branco.png',
    socialMediaLink: 'https://www.instagram.com/navalhapretonobranco/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '20% OFF',
    imagePath: '../../../assets/partners/mundaka.png',
    socialMediaLink: 'https://www.instagram.com/mundakaventura/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/ortoart.png',
    socialMediaLink: 'https://www.instagram.com/draericahenrique_ortoart/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '15% OFF',
    imagePath: '../../../assets/partners/borracharia-sao-cristovao.png',
    socialMediaLink: 'https://www.instagram.com/borrachariasaocristovao/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '15% OFF',
    imagePath: '../../../assets/partners/ride-again.png',
    socialMediaLink: 'https://www.instagram.com/lojarideagain/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/irene-marques.png',
    socialMediaLink: 'https://www.instagram.com/temakeria.irene.marques/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/alakazan.png',
    socialMediaLink: 'https://www.instagram.com/alakazansocorro',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/imperio_das_carnes.png',
    socialMediaLink: 'https://www.instagram.com/imperiodascarnes_acougue',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/varejao_das_carnes.png',
    socialMediaLink: 'https://www.instagram.com/varejaodascarnes_/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '7% OFF',
    imagePath: '../../../assets/partners/barel-reparos-eletronicos.png',
    socialMediaLink: 'https://www.instagram.com/barelreparoseletronicos.1/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/best-click.png',
    socialMediaLink: 'https://www.instagram.com/bestclick360/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/pedro-leinat.png',
    socialMediaLink: 'https://www.instagram.com/pedro.leinat/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/wine-import.png',
    socialMediaLink: 'https://www.instagram.com/wineimportshop/',
  },

  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/fernanda-bertoni.png',
    socialMediaLink: 'https://www.instagram.com/drafernandabertoni/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/invicta-brindes.png',
    socialMediaLink: 'https://www.instagram.com/invictabrindes/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/junior-suplementos.png',
    socialMediaLink: 'https://www.instagram.com/juniors.suplementos/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/dekker.png',
    socialMediaLink: 'https://www.instagram.com/dekkerdistribuidora/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/tina-fit.png',
    socialMediaLink: 'https://www.instagram.com/tinamodafitness/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '15% OFF',
    imagePath: '../../../assets/partners/kouti-clean.png',
    socialMediaLink: 'https://www.instagram.com/kouti_clean/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '3% OFF',
    imagePath: '../../../assets/partners/cafe-por-do-sol.png',
    socialMediaLink: 'https://www.instagram.com/cafepordosoloficial/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/tafner-engenharia.png',
    socialMediaLink: 'https://wa.me/5519991399538/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/posto-ipiranga.png',
    socialMediaLink: 'https://www.instagram.com/posto_ipiranga_socorro/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/roller.png',
    socialMediaLink: 'https://www.instagram.com/roller_envelopamentos_/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '15% OFF',
    imagePath: '../../../assets/partners/rei-do-oleo.png',
    socialMediaLink: 'https://www.instagram.com/reidooleosocorro/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '15% OFF',
    imagePath: '../../../assets/partners/ponto-da-contabilidade.png',
    socialMediaLink: 'https://www.instagram.com/pontodacontabilidade/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '15% OFF',
    imagePath: '../../../assets/partners/biogalli.png',
    socialMediaLink: 'https://www.instagram.com/biogalli_/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '10% OFF',
    imagePath: '../../../assets/partners/dra-carolina-godoy.png',
    socialMediaLink: 'https://www.instagram.com/dracarolina.godoy/',
  },
  {
    id: Math.random(),
    partner: '',
    discountAmount: '5% OFF',
    imagePath: '../../../assets/partners/cb-socorro.png',
    socialMediaLink: 'https://www.instagram.com/cbtogo.socorrosp',
  },
];
