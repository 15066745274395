import styled from 'styled-components';

import backgroundImg from '../../../assets/mosaico.png';

export const Container = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
`;

export const ImageBox = styled.div`
    background: url(${backgroundImg}) no-repeat center;
    background-size: cover;
    height: calc(100vh - 60px);
    height: 200px;

    width: 95%;
    border-radius: 5px;
    /* border: 1px solid #FCFE38; */
`;

export const Content = styled.div`
    margin: 0;
    flex-direction: column;
    display: flex;
    place-content: center;

    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    display: flex;
    margin-bottom: 15px;
    h1 {
        margin-top: 10px;
        font-size: 80px;
        padding: 0 16px;
        border-radius: 3px;

        @media (max-width: 500px) {
            font-size: 50px;
        }
    }

    place-content: center;
`;

export const Section = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    margin-bottom: 40px;

    flex-wrap: wrap;
`;

export const Subtitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    border-radius: 3px;
    border: 0px;
    padding: 0 16px;
    color: #FCFE38;
    font-weight: bold;
    font-size: 35px;
    margin: 15px;

    @media (max-width: 500px) {
            font-size: 20px;
    }

    svg {
        margin-bottom: 20px;
        color: #858585;
        width: 60px;
        height: 60px;
    }
`;

export const Description1 = styled.div`
    /* background-color: #000; */
    border-radius: 3px;
    border: 0px;
    padding: 0 16px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;

    @media (max-width: 500px) {
        font-size: 15px;
    }
`;

export const Description2 = styled.div`
    /* background-color: #000; */
    border-radius: 3px;
    border: 0px;
    padding: 0 16px;
    color: #fff;
    font-weight: 400;
    font-size: 20px;

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;

    @media (max-width: 500px) {
        font-size: 15px;
    }
`;

export const Description3 = styled.div`
    /* background-color: #000; */
    border-radius: 3px;
    border: 0px;
    padding: 0 16px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;
`;
