import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
`;

export const Content = styled.main`
    margin: 64px auto;
    display: flex;

    place-content: center;

    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    display: flex;

    place-content: center;
`;

export const Option = styled.div`
    background: #FCFE38;
    height: 56px;
    border-radius: 3px;
    border: 0px;
    padding: 0 16px;
    color: #312e38;
    width: 200px;
    font-weight: bold;
    transition: background-color 0.2s;

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;

    &:hover {
        background: ${shade(0.2, '#FCFE38')};
        cursor: pointer;
    }
`;

export const SpecialOption = styled.button`
    background: rgba(230, 232, 74, 0.41);
    height: 56px;
    border-radius: 3px;
    border: 2px dashed #FCFE38;
    padding: 0 16px;
    color: #FCFE38;
    width: 200px;
    font-weight: bold;
    transition: background-color 0.2s;

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;

    &:hover {
        /* background: ${shade(0.5, '#FCFE38')}; */
        cursor: pointer;
    }
`;
