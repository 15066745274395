import styled from 'styled-components';

export const Container = styled.main`
`;

export const Content = styled.div`
    margin: 10px 10px 0 10px;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;

    flex-wrap: wrap;
`;

export const DayContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

export const PageTitle = styled.div`
    display: flex;
    place-content: center;
`;

export const Day = styled.div`
    border-radius: 3px;
    background: #28262E;
    border: 0px;
    padding: 0 16px;
    color: #FCFE38;
    font-weight: bold;

    margin: 0 10px 15px 10px;

    align-items: center;
    text-align: center;

`;

export const CardsContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    padding: 0 16px;
    border-bottom: 2px solid #FFF;
`;

export const CardsBox = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;

    @media (max-width: 500px) {
        flex-wrap: wrap;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    width: 310px;
    height: 60px;
    border-radius: 10px;
    background: #28262E;
    padding: 6px;
    margin-right: 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
        width: 20px;
        height: 20px;
    }

    @media (max-width: 500px) {
        margin-bottom: 8px;
    }
`;

export const Button = styled.button`
    display: flex;
    border: 0;
    align-items: center;
    place-content: center;
    width: 210px;
    height: 45px;
    border-radius: 4px;
    background: #FCFE38;
    font-weight: 800;
    color: #28262E;
    padding: 6px;

    margin: 15px 0;

    :hover {
        cursor: pointer;
    }
`;

export const ItemsCardContainer = styled.div`
    display: flex;
    align-items: center;
    place-content: center;
`;

export const DateLabel = styled.h1`
    font-size: 17px;
    font-weight: 500;
`;

export const EventTitle = styled.h1`
    font-size: 25px;
    color: #FCFE38;
    margin-left: 6px;
    margin-bottom: 10px;
    font-weight: 700;
`;

export const DateValue = styled.h1`
    font-size: 17px;
    color: #FCFE38;
    margin-left: 6px;
    font-weight: 500;
`;

export const CoachValue = styled.div`
    font-size: 17px;
    color: #FCFE38;
    margin-left: 6px;
    font-weight: 500;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ListTitle = styled.h1`
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
    color: #FFF;
`;

export const ConfirmedContainer = styled.div`
    width: 70%;

    @media (max-width: 500px) {
        width: 95%;
    }
`;

export const ConfirmedBox = styled.div`
    display: flex;
    /* width: 80%; */
    height: 50px;
    align-items: center;
    margin-top: 5px;
    border-bottom: 1px solid #555555;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
        width: 20px;
        height: 20px;
        color: #03C40B;
        margin-right: 15px;
    }
`;

export const ConfirmedContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    place-content: center;
    font-size: 13px;
    font-weight: 500;
    background: #28262E;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 3px 6px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    h2 {
        margin-top: 3px;
        font-size: 10px;
        color: #928D8D;
    }
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 20px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;

export const ActionsContainer = styled.div`
    display: flex;
    /* background: #000; */
    align-items: center;
    place-content: center;
    width: 95%;
    /* padding: 0 5px; */

    button {
        margin: 10px;
    }
`;
