import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FiPower, FiHome } from 'react-icons/fi';
import { FaThLarge } from 'react-icons/fa';

import { Link } from 'react-router-dom';

import {
  HeaderContainer, HeaderContent, Profile,
} from './styles';

import { Home } from '../../styles/Icons';

import { useAuth } from '../../hooks/auth';

const Header: React.FC = () => {
  const { signOut, user } = useAuth();

  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    const formatName = user.name.split(' ');

    setFirstName(formatName[0]);
  }, []);

  const history = useHistory();

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <Profile>
            <FaThLarge
              onClick={() => { history.push('/home'); }}
            />
            <div>
              <span>Bem-vindo(a),</span>
              <Link to="/profile">
                <strong>{firstName}</strong>
                <span>!</span>
              </Link>
            </div>
          </Profile>

          <button type="button" onClick={signOut}>
            <FiPower />
          </button>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};

export default Header;
