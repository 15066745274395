import styled from 'styled-components';

export const Container = styled.main`
`;

export const Content = styled.div`
    margin: 64px 0;

    place-content: center;

    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    display: flex;
    place-content: center;
`;
