import styled from 'styled-components';

export const Container = styled.main`
`;

export const Content = styled.div`
    margin: 64px 0;
    place-content: center;

    flex-wrap: wrap;
`;

export const DayContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`;

export const PageTitle = styled.div`
    display: flex;
    place-content: center;
`;

export const Day = styled.div`
    border-radius: 3px;
    background: #28262E;
    border: 0px;
    padding: 0 16px;
    color: #FCFE38;
    font-weight: bold;

    width: 95%;

    margin: 0 10px 15px 10px;

    align-items: center;
    text-align: center;

`;

export const CardsContainer = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;
    padding: 0 16px;
    overflow-x: auto;

    flex-wrap: wrap;

    @media (max-width: 500px) {
        flex-wrap: nowrap;

        align-items: unset;
        place-content: unset;
    }
`;

export const Card = styled.div`
    width: 160px;
    height: 80px;
    border-radius: 10px;
    background: #28262E;
    border: 2px solid #555555;
    padding: 6px;
    flex-shrink: 0;
    margin-left: 15px;
    margin-bottom: 14px;

    @media (max-width: 500px) {
        margin-left: 0;

        & + div {
            margin-left: 15px;
        }
    }


    :hover {
        cursor: pointer;
        border: 2px solid #FCFE38;
    }
`;

export const ItemsCardContainer = styled.div`
    display: flex;
`;

export const DateLabel = styled.h1`
    font-size: 17px;
    font-weight: 500;
`;

export const DateValue = styled.h1`
    font-size: 17px;
    color: #FCFE38;
    margin-left: 6px;
    font-weight: 500;
`;

export const OthersLabel = styled.h1`
    font-size: 13px;
    font-weight: 500;
`;

export const OthersValue = styled.h1`
    font-size: 13px;
    font-weight: 300;
    margin-left: 6px;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    place-content: center;
    margin-top: 20px;

    // Para que a primeira modalidade não fique muito abaixo do botão de add
    margin-bottom: -50px;
`;

export const AddButton = styled.div`
    display: flex;
    place-content: center;
    align-items: center;
    width: 57px;
    height: 57px;
    background: #28262E;
    border-radius: 50px;

    svg {
        width: 40px;
        height: 40px;
        color: #FCFE38;
    }

    :hover {
        cursor: pointer;
    }

    @media (max-width: 500px) {
        height: 50px;
        width: 50px;
    }
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 20px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;
