import React from 'react';

import {
  Container, Content, PageTitle, Description, Day, TitleClass, DayContainer, ChangeClassDescription, ChangeClass,
} from './styles';

const Timesheet: React.FC = () => (
  <Container>

    <PageTitle>
      <h1>Quadro de horários</h1>
    </PageTitle>


    <Content>
      <DayContainer>
        <Day>
          <h1>SEGUNDA-FEIRA</h1>
          <h2>
            (Treino A)
          </h2>
        </Day>
        <Description>
          <TitleClass>
            Funcional:

          </TitleClass>

          <h1>
            06h | 07h | 08h | 18h | 19h | 20h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Funcional Seniors:
          </TitleClass>
          <h1>
            09h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Bike:
          </TitleClass>
          <h1>
            18h | 19h
          </h1>
        </Description>
      </DayContainer>

      <DayContainer>

        <Day>
          <h1>TERÇA-FEIRA</h1>
          <h2>
            (Treino A)
          </h2>
        </Day>
        <Description>
          <TitleClass>
            Funcional:
          </TitleClass>

          <h1>
            06h | 08 | 16h | 18h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            LPO:
          </TitleClass>

          <h1>
            07h | 19h | 20h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Bike:
          </TitleClass>
          <h1>
            06h | 07h | 18h | 19h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Kids:
          </TitleClass>
          <h1>
            08h | 17h
          </h1>
        </Description>
      </DayContainer>

      <DayContainer>

        <Day>
          <h1>QUARTA-FEIRA</h1>
          <h2>
            (Treino B)
          </h2>
        </Day>
        <Description>
          <TitleClass>
            Funcional:

          </TitleClass>

          <h1>
            06h | 07h | 08h | 18h | 19h | 20h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Funcional Seniors:
          </TitleClass>
          <h1>
            09h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Bike:
          </TitleClass>
          <h1>
            18h | 19h
          </h1>
        </Description>
      </DayContainer>

      <DayContainer>
        <Day>
          <h1>QUINTA-FEIRA</h1>
          <h2>
            (Treino B)
          </h2>
        </Day>
        <Description>
          <TitleClass>
            Funcional:
          </TitleClass>

          <h1>
            06h | 08 | 16h | 18h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            LPO:
          </TitleClass>

          <h1>
            07h | 19h | 20h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Bike:
          </TitleClass>
          <h1>
            06h | 07h | 18h | 19h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Kids:
          </TitleClass>
          <h1>
            08h | 17h
          </h1>
        </Description>
      </DayContainer>

      <DayContainer>
        <Day>
          <h1>SEXTA-FEIRA</h1>
          <h2>
            (Treino C)
          </h2>
        </Day>
        <Description>
          <TitleClass>
            Funcional:

          </TitleClass>

          <h1>
            06h | 07h | 08h | 16h | 18h | 19h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            Funcional Seniors:
          </TitleClass>
          <h1>
            09h
          </h1>
        </Description>

        <Description>
          <TitleClass>
            LPO:
          </TitleClass>
          <h1>
            20h
          </h1>
        </Description>
      </DayContainer>

      <DayContainer>
        <ChangeClass>
          <h1>IMPORTANTE</h1>
        </ChangeClass>
        <ChangeClassDescription>
          <TitleClass />
          <div>
            <h1>
              Todo aluno poderá repor o seu treino no horário de sua escolha, desde que esse seja agendado pelo APLICATIVO, com antecedência.
            </h1>

            {/* <h1>
              A única restrição é que o limite máximo de alunos daquela turma não poderá exceder.
            </h1> */}
          </div>
        </ChangeClassDescription>
      </DayContainer>

    </Content>
  </Container>
);


export default Timesheet;
