import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  Option,
  SpecialOption,
  PageTitle,
} from './styles';

import { useAuth } from '../../hooks/auth';

import { DialogPayment } from '../../components/DialogPayment';

const HomeMembers: React.FC = () => {
  const { user } = useAuth();

  const history = useHistory();

  return (
    <Container>
      <PageTitle>
        <h1>Home</h1>
      </PageTitle>

      {user.permission === 'coach' ? (
        <Content>

          <Option
            onClick={() => { history.push('/teach-groups'); }}

          >
            Aulas

          </Option>
          <Option
            onClick={() => { history.push('/events'); }}
          >
            Eventos

          </Option>
          <Option
            onClick={() => { history.push('/dashboard-members'); }}
          >
            Alunos

          </Option>
          <Option
            onClick={() => { history.push('/wods'); }}
          >
            WOD

          </Option>
          <Option
            onClick={() => { history.push('/dashboard-classes'); }}
          >
            Turmas

          </Option>

          <Option
            onClick={() => { history.push('/dashboard-members-registered'); }}
          >
            Painel de alunos matriculados

          </Option>
          <Option
            onClick={() => { history.push('/waiting-list'); }}
          >
            Lista de espera

          </Option>
          <Option
            onClick={() => { history.push('/experimental-list'); }}
          >
            Alunos experimentais

          </Option>
          <Option
            onClick={() => { history.push('/birthdays'); }}
          >
            Aniversariantes do mês

          </Option>

        </Content>
      ) : (
        <Content>

          <Option
            onClick={() => { history.push('/check-in'); }}
          >
            Check-in

          </Option>
          <Option
            onClick={() => { history.push('/my-wods'); }}

          >
            WOD

          </Option>
          <Option
            onClick={() => { history.push('/timesheet'); }}
          >
            Quadro de horários

          </Option>
          <Option
            onClick={() => { history.push('/contracts'); }}
          >
            Planos

          </Option>
          <Option
            onClick={() => { history.push('/events'); }}
          >
            Eventos

          </Option>
          <Option
            onClick={() => { history.push('/who-we-are'); }}
          >
            Sobre nós

          </Option>
          <SpecialOption
            onClick={() => { history.push('/recurring-credit'); }}
          >
            Contrate seu plano por aqui

          </SpecialOption>
          <SpecialOption
            onClick={() => { history.push('/partnerships'); }}
          >
            Parceiros 2LIFE

          </SpecialOption>

        </Content>
      )}

      <DialogPayment />
    </Container>
  );
};


export default HomeMembers;
