import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import {
  Container, Content, OptionSuccess, OptionFail,
} from './styles';
import { useAuth } from '../../hooks/auth';

const RecurringCredit: React.FC = () => {
  const { user } = useAuth();

  function handleLinkToWhatsAppSuccess() {
    window.open(`https://wa.me/5519997666396/?text=Olá!%20Venho%20informar%20que%20acabei%20de%20contratar%20um%20Plano%20pelo%20*Crédito%20Recorrente%20da%20Vindi*.%20Meu%20nome%20é%20*${user.name}*.`, '_blank');
  }

  function handleLinkToWhatsAppFail() {
    window.open('https://wa.me/5519997666396/?text=Olá.%20Estou%20com%20dúvidas%20e%20dificuldades%20para%20contratar%20o%20*crédito%20recorrente*.%20Poderia%20me%20ajudar?', '_blank');
  }

  return (
    <Container>
      <Content>
        <FiCheckCircle />
        <h1>Prontinho!</h1>
        <h2>
          Se a contratação do seu Plano foi feita com sucesso, é
          só comunicar a nossa Secretaria que deu tudo certo.
        </h2>
        <h3>Para fazer isso diretamente pelo WhatsApp, clique no botão abaixo...</h3>

        <OptionSuccess onClick={handleLinkToWhatsAppSuccess}>
          Comunicar sucesso
        </OptionSuccess>

        <h2>
          Agora, se o seu pagamento não pôde ser processado, clique
          {' '}
          <a href="/recurring-credit">aqui</a>
          {' '}
          para voltar às opções de Planos e tentar novamente ou, se preferir, clique
          {' '}
          <a href="/home">aqui</a>
          {' '}
          para voltar ao menu principal.
        </h2>

        <h3>Caso ainda reste alguma dúvida, nos chame no WhatsApp, tá bom?</h3>

        <OptionFail onClick={handleLinkToWhatsAppFail}>
          Dúvidas
        </OptionFail>
      </Content>
    </Container>
  );
};


export default RecurringCredit;
