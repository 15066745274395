import React from 'react';

import { useHistory } from 'react-router-dom';
import logoImg from '../../assets/logo.svg';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import {
  Container, CheckinIcon, HomeIcon, EventIcon, WodIcon, Option, TimeBoardIcon, MissionIcon, WoWeAreIcon, PlansIcon, ClassIcon, EnrolledMemberIcon, WaitingListIcon, BirthdayIcon, ExperimentalMemberIcon, TeachIcon,
} from './styles';

const Sidebar: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { user } = useAuth();
  return (

    <>
      {/* Se o usuário for um professor, ele mostra a barra lateral abaixo */}
      {user.permission === 'coach' ? (
        <Container>
          <img src={logoImg} alt="2LIFE" />

          <Option
            onClick={() => { history.push('/home'); }}
          >
            <HomeIcon />
            <span>Home</span>
          </Option>

          <Option
            onClick={() => { history.push('/teach-groups'); }}
          >
            <TeachIcon />
            <span>Aulas</span>
          </Option>

          <Option
            onClick={() => { history.push('/events'); }}
          >
            <EventIcon />
            <span>Eventos</span>
          </Option>

          <Option
            onClick={() => { history.push('/dashboard-members'); }}
          >
            <WoWeAreIcon />
            <span>Alunos</span>
          </Option>

          <Option
            onClick={() => { history.push('/wods'); }}
          >
            <WodIcon />
            <span>WOD</span>
          </Option>

          <Option
            onClick={() => { history.push('/dashboard-classes'); }}
          >
            <ClassIcon />
            <span>Turmas</span>
          </Option>

          <Option
            onClick={() => { history.push('/dashboard-members-registered'); }}
          >
            <EnrolledMemberIcon />
            <span>Alunos Matriculados</span>
          </Option>

          <Option
            onClick={() => { history.push('/waiting-list'); }}
          >
            <WaitingListIcon />
            <span>Lista de espera</span>
          </Option>

          <Option
            onClick={() => { history.push('/experimental-list'); }}
          >
            <ExperimentalMemberIcon />
            <span>Alunos experimentais</span>
          </Option>

          <Option
            onClick={() => { history.push('/birthdays'); }}
          >
            <BirthdayIcon />
            <span>Aniversariantes do mês</span>
          </Option>
        </Container>
      ) : (
        <Container>
          <img src={logoImg} alt="2LIFE" />

          <Option
            onClick={() => { history.push('/home'); }}
          >
            <HomeIcon />
            <span>Home</span>
          </Option>

          <Option
            onClick={() => { history.push('/check-in'); }}
          >
            <CheckinIcon />
            <span>Check-in</span>
          </Option>

          <Option
            onClick={() => { history.push('/my-wods'); }}
          >
            <WodIcon />
            <span>WOD</span>
          </Option>

          <Option
            onClick={() => { history.push('/timesheet'); }}
          >
            <TimeBoardIcon />
            <span>Quadro de horários</span>
          </Option>

          <Option
            onClick={() => { history.push('/contracts'); }}
          >
            <PlansIcon />
            <span>Planos</span>
          </Option>

          <Option
            onClick={() => { history.push('/events'); }}
          >
            <EventIcon />
            <span>Eventos</span>
          </Option>

          <Option
            onClick={() => { history.push('/who-we-are'); }}
          >
            <WoWeAreIcon />
            <span>Sobre nós</span>
          </Option>

          <Option
            onClick={() => { history.push('/recurring-credit'); }}
          >
            <PlansIcon />
            <span>Contrate seu plano por aqui</span>
          </Option>

          <Option
            onClick={() => { history.push('/partnerships'); }}
          >
            <WoWeAreIcon />
            <span>Parceiros 2LIFE</span>
          </Option>
        </Container>
      )}
    </>

  );
};

export default Sidebar;
