import styled from 'styled-components';

export const Container = styled.main`
`;

export const Content = styled.div`
    margin: 64px 0;

    place-content: center;

    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    display: flex;
    place-content: center;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    place-content: center;
    margin-top: 20px;

    // Para que a primeira modalidade não fique muito abaixo do botão de add
    margin-bottom: -50px;
`;

export const AddButton = styled.div`
    display: flex;
    place-content: center;
    align-items: center;
    width: 57px;
    height: 57px;
    background: #28262E;
    border-radius: 50px;

    svg {
        width: 40px;
        height: 40px;
        color: #FCFE38;
    }

    :hover {
        cursor: pointer;
    }

    @media (max-width: 500px) {
        height: 50px;
        width: 50px;
    }
`;
