import React, {
  useCallback, useRef, useEffect, useState, ChangeEvent,
} from 'react';
import {
  FiType,
} from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {
  Container, Content, InputContainer, TagInput, SelectContainer,
} from './styles';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

interface Modalities {
    id: string;
    title: string | null;
}

interface SignUpFormData {
    title: string;
    description: string;
}

const NewClass: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { addToast } = useToast();

  const [modalities, setModalities] = useState<Modalities[]>([]);
  const [selectModality, setSelectModality] = useState<Modalities>();

  useEffect(() => {
    api.get('/modality').then((response) => setModalities(response.data));
  }, []);

  // Captura o ID e o TEXTO da modalidade selecionada e salva em um estado de objeto tipado
  const onChangeSelect = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    const title = event.target.children[event.target.selectedIndex].textContent;

    const union: Modalities = { id, title };

    setSelectModality(union);
  }, []);

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Obrigatório'),
          description: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          title,
          description,
        } = data;

        const formData = {
          title,
          description,
          modality: selectModality?.title,
          modality_id: selectModality?.id,
        };

        await api.post('/class', formData);

        history.push('/dashboard-classes');

        addToast({
          type: 'sucess',
          title: 'Sucesso!',
          description: 'Turma cadastrada.',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'É bem provável que um nome idêntico já está sendo usado para uma turma dessa mesma modalidade.',
      });
    }
  }, [addToast, history, selectModality]);

  return (
    <Container>
      <Content>
        <h1>Nova turma</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputContainer>
            <TagInput>Modalidade: </TagInput>
            <SelectContainer>
              <select name="modality" id="modality" onChange={onChangeSelect}>
                <option value="0">Selecione uma modalidade</option>
                {modalities.map((modality) => (
                  <option key={modality.id} value={modality.id}>{modality.title}</option>
                ))}
              </select>
            </SelectContainer>
          </InputContainer>

          <InputContainer>
            <TagInput>Título: </TagInput>
            <Input name="title" icon={FiType} />
          </InputContainer>

          <InputContainer>
            <TagInput>Dias: </TagInput>
            <Input name="description" icon={FiType} />
          </InputContainer>

          <Button type="submit">Cadastrar</Button>

        </Form>
      </Content>
    </Container>
  );
};


export default NewClass;
