import React from 'react';

import { FaUsers, FaCheckCircle } from 'react-icons/fa';
import { FiTarget } from 'react-icons/fi';
import {
  Container, Content, PageTitle, Section, Subtitle, ImageBox, Description1, Description2, Description3,
} from './styles';

const WhoWeAre: React.FC = () => (
  <Container>
    <PageTitle>
      <h1>Sobre nós</h1>
    </PageTitle>


    <Content>
      <Section>
        <Subtitle>
          <FaUsers />
          AFINAL, O QUE É A 2LIFE?
        </Subtitle>

        {/* <ImageBox /> */}

        <Description1>
          A 2LIFE é o maior e mais completo Centro de Treinamento de Socorro.
        </Description1>

        <Description2>
          Oferecemos aula de Treinamento Funcional, Levantamento Olímpico, Ginástica, Bike e um projeto voltado às crianças dos 7 aos 13 anos; um misto de treino funcional com brincadeiras, conhecido como 2LIFE Kids.
        </Description2>

        <Description2>
          Todos os nossos programas de treino são realizados em grupo, com diversas opções de horários e dias de semana, principalmente para atender a todos os nossos alunos.
        </Description2>
      </Section>

      <Section>
        <Subtitle>
          <FiTarget />
          NOSSA MISSÃO
        </Subtitle>

        <Description1>
          Nossa missão é promover saúde, através do exercício físico e relacionamento humano.
        </Description1>
      </Section>

    </Content>
  </Container>
);


export default WhoWeAre;
