import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
    background: #FCFE38;
    height: 56px;
    border-radius: 3px;
    border: 0px;
    padding: 0 16px;
    color: #312e38;
    width: 100%;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 5px;
    transition: background-color 0.2s;

    &:hover {
        background: ${shade(0.2, '#FCFE38')};
    }

    @media (max-width: 500px) {
        height: 50px;
    }
`;
