import React, { useState } from 'react';

import {
  Box,
  Button,
  Container,
  Overlay,
} from './styles';


export function DialogPayment() {
  const [read, setRead] = useState<boolean>(() => {
    try {
      const warning = localStorage.getItem('@2LIFE APP (1.0): aviso 1/2023');
      return !!warning;
    } catch (error) {
      return false;
    }
  });

  function handleClick() {
    localStorage.setItem('@2LIFE APP (1.0): aviso 1/2023', 'lido');

    setRead(true);
  }

  if (read) return null;

  return (
    <>
      <Overlay />
      <Container>
        <span>Temos alguns recadinhos pra você</span>

        <Box>
          <p>Olá queridos alunos. Atenção para os recados.</p>
        </Box>

        <Box>
          <p>
            <strong>RECADINHO 1: </strong>
            Com o objetivo de informar e não criar nenhum transtorno, a partir do mês de
            {' '}
            <span>setembro</span>
            {' '}
            temos uma nova regra em relação aos pagamentos:
          </p>

          <p>
            Mensalidades com
            {' '}
            <span>60 dias</span>
            {' '}
            de atraso (completando duas) o plano será automaticamente
            bloqueado pelo nosso sistema, com isso o aluno só será liberado para treinar após
            o acerto de pelo menos uma mensalidade.
          </p>

          <p>
            Entendemos que imprevistos financeiros acontecem e não queremos que você deixe de
            cuidar da sua saúde por conta disso, portanto, caso você queira conversar para
            negociar e justificar estamos sempre à disposição.
          </p>
        </Box>

        <Box>
          <p>
            <strong>RECADINHO 2: </strong>
            A partir do mês de
            {' '}
            <span>dezembro de 2023</span>
            {' '}
            teremos um reajuste
            em nossos planos.
          </p>
        </Box>

        <Box>
          <p>Obrigado, qualquer dúvida ficamos à disposição!</p>
        </Box>

        <Button onClick={handleClick}>Entendi</Button>
      </Container>
    </>
  );
}
