import styled from 'styled-components';

export const Container = styled.main`
`;

export const Content = styled.div`
    margin: 64px auto;
    place-content: center;

    flex-wrap: wrap;
`;

export const DayContainer = styled.div`
   display: flex;
   flex-direction: column;
   /* align-items: center; */
`;

export const Day = styled.div`
    border-radius: 3px;
    background: #28262E;
    border: 0px;
    padding: 0 16px;
    color: #FCFE38;
    font-weight: bold;

    margin: 0 10px 15px 10px;

    align-items: center;
    text-align: center;

`;

export const CardsContainer = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;
    padding: 0 16px;
    overflow-x: auto;

    flex-wrap: wrap;

    @media (max-width: 500px) {
        justify-content: space-around;
    }
`;

export const Card = styled.div`
    width: 160px;
    height: 95px;
    border-radius: 10px;
    background: #28262E;
    border: 2px solid #555555;
    padding: 6px;
    flex-shrink: 0;
    margin-left: 15px;
    margin-bottom: 4px;

    @media (max-width: 500px) {
        margin-left: 0;
    }


    :hover {
        cursor: pointer;
        border: 2px solid #FCFE38;
    }
`;

export const ItemsCardContainer = styled.div`
    display: flex;
`;

export const DateLabel = styled.h1`
    font-size: 17px;
    font-weight: 500;
`;

export const DateValue = styled.h1`
    font-size: 17px;
    color: #FCFE38;
    margin-left: 6px;
    font-weight: 500;
`;

export const OthersLabel = styled.h1`
    font-size: 13px;
    font-weight: 500;
`;

export const OthersValue = styled.h1`
    font-size: 13px;
    font-weight: 300;
    margin-left: 6px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 20px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;
