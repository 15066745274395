import React from 'react';
import {
  Container,
  PageTitle,
  Content,
  DisplayContainer,
  Banner,
  Label,
} from './styles';
import { partners } from './partners';

const PartnerDisplay: React.FC = () => (
  <Container>
    <PageTitle>
      <h1>Parceiros 2LIFE</h1>
      <h2>Clique na imagem e vá direto para o Instagram da empresa ;)</h2>
    </PageTitle>
    <Content>
      {partners.map((partner) => (
        <DisplayContainer key={partner.id}>
          <Banner imagePath={partner.imagePath} href={partner.socialMediaLink} target="_blank" />
          <Label>
            <h1>{partner.discountAmount}</h1>
          </Label>
        </DisplayContainer>
      ))}
    </Content>
  </Container>
);


export default PartnerDisplay;
