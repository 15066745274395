import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #232129;
  display: flex;
  width: 100%;
  height: 47px;
  max-height: 57px;
  flex-direction: column;
  position: relative;
  border: 2px solid #232129;
  border-radius: 4px;

  ${(props) => props.isErrored && css`
        border-color: #0B7FE3;
    `}

    ${(props) => props.isFocused && css`
        color: #FCFE38;
        border-color: #FCFE38;
    `}

    ${(props) => props.isFilled && css`
        color: #FCFE38;
    `}

    .Active {
        transform: translate(0, 2px) scale(0.75);
        /* color: #33415C; */
    }

    input {
        height: 100%;
        width: 100%;
        padding: 14px 16px 0 10px;
        outline: 0;
        border: 0;
        background: transparent;
        font-size: 16px;
        font-weight: 600;
        color: #FFF;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    svg {
        margin-right: 16px;
    }

    label {
        font-size: 16px;
        /* font-family: Arial, Helvetica, sans-serif; */
        padding: 0 15px;
        color: #999;
        pointer-events: none;

        position: absolute;
        transform: translate(0, 13px) scale(1);
        transform-origin: top left;
        transition: all 0.2s ease-out;
    }

    :focus-within label {
        transform: translate(0, 2px) scale(0.75);
        /* color: #33415C; */
    }
`;

export const Error = styled(Tooltip)`

    svg {

    }

    span {
        background: #C53030;
        color: #FFF;

        &::before {
            border-color: #C53030 transparent;
        }
    }
`;
