import styled from 'styled-components';
import { shade } from 'polished';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';


export const Container = styled.main`
align-items: center;
display: flex;
flex-direction: column;

    form {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        align-items: center;
        place-content: center;
}
`;

export const Content = styled.div`
    margin: 30px 90px;
    display: flex;
    place-content: center;
    align-items: center;
    flex-wrap: wrap;

    /* background: #000; */

    @media (max-width: 500px) {
        margin: 30px 0;
    }
`;

export const PageTitle = styled.div`
    display: flex;

    place-content: center;
`;

export const DatesContainer = styled.div`
    display: flex;
    place-content: center;
    justify-content: space-around;

    > div {
        margin-top: 10px;

        margin-right: 5px;
    }

    @media (max-width: 500px) {
        /* flex-wrap: wrap; */

        > div {
            margin-bottom: 10px;
        }
    }
`;

export const DatesContent = styled.div`
    /* margin: 30px 90px; */
    display: flex;
    width: 400px;

    place-content: center;
    /* flex-wrap: wrap; */

    /* background: #FFF; */

    @media (max-width: 500px) {
        width: 200px;
    }
`;

// *** GRID ***
export const SearchButton = styled.button`
    place-content: center;
    width: 57px;
    height: 57px;
    background: #131216;
    border: 0;
    border-radius: 0 7px 7px 0;

    @media (max-width: 500px) {
        height: 50px;
    }
`;

export const AddButton = styled.button`
    place-content: center;
    width: 57px;
    height: 57px;
    background: #131216;
    border: 0;
    border-radius: 10px 0 0 10px;

    @media (max-width: 500px) {
        height: 50px;
        /* padding: 0 10px 0 10px; */
    }
`;

export const ContainerHeaderGrid = styled.div`
    width: 80%;
    display: flex;
    border-radius: 2px;

    /* background: #FFF; */

    & + div {
        margin-top: 16px;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const DivGrid = styled.div`
    width: 100%;
    /* display: flex; */
    align-items: center;
`;

export const ItemsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;
`;

export const HeaderName = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 100%;
    text-align: center;

    /* @media (max-width: 500px) {
        width: 150px;
    } */

    /* background: #000; */
`;

export const HeaderModality = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 300px;
    text-align: center;

    @media (max-width: 500px) {
        width: 150px;
    }

    /* background: #000; */
`;

export const HeaderDueDate = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 100%;
    text-align: center;

    /* @media (max-width: 500px) {
        width: 100px;
    } */

    /* background: #000; */
`;

export const MembersList = styled.div`
    width: 80%;
    display: flex;
    border-bottom: #4C4A52 solid 1px;

    & + div {
        /* width: 80%; */
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const Grid = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;

    :hover {
        cursor: pointer;
        background: #232129;
    }
`;

export const MemberName = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 100%;
    height: 50px; // Controla a altura de toda a linha
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
        /* width: 150px; */
        font-size: 15px;
        height: 35px; // Controla a altura de toda a linha
    }

    /* background: #000; */
`;

export const MemberModality = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
        width: 150px;
        font-size: 13px;
    }

    /* background: #000; */
`;

export const MemberDueDate = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
        width: 100px;
        font-size: 10px;
    }

    /* background: #000; */
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 25px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;

export const KeyboardTimePickerInput = styled(KeyboardDatePicker)`

    .MuiInput-underline:before {
        border-bottom-color: #FFF;
    }

    .MuiInput-underline:after {
        border-bottom-color: #FFF;
    }

    // Formata a cor do label em caso de foco
    .MuiFormLabel-root.Mui-focused {
        color: #FFF;
    }

    .MuiFormLabel-root {
        color: #FFF;
    }

    // Regula o tamanho da fonte do texto digitado
    .MuiInputBase-input {
        /* color: #FFF; */
    }

    .MuiInputBase-root {
        color: #FFF;
    }

    .MuiPickersDay-daySelected {
      color: #000;
      font-weight: 500;
      background-color: #000;
    }
.MuiPickersDay-daySelected:hover {
  background-color: #000;
}
.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}
`;
