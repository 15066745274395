import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
        margin-bottom: 30px;
    }

    form {
        width: 540px;
        text-align: center;

        @media (max-width: 500px) {
            width: 340px;

            h1 {
                margin-top: 10px;
                font-size: 25px;
            }
        }
    }
`;

export const SelectContainer = styled.div`
    background: #232129;
    border-radius: 3px;
    padding: 16px;
    width: 100%;
    height: 57px;
    color: #666360;
    border: 2px solid #232129;

    align-items: center;
    display: flex;
    margin-bottom: 8px;

    select {
        flex: 1;
        background: #232129;
        border: 0;
        font-family: 'Roboto Slab';
        color: #FFF;
    }

    @media (max-width: 500px) {
        height: 50px;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    margin-bottom: 8px;

    div {
        border-radius: 0 5px 5px 0;
    }

    @media (max-width: 500px) {}
`;

export const TagInput = styled.button`
    display: flex;
    place-content: initial;
    align-items: center;
    width: 110px;
    height: 57px;
    background: #1F1D24;
    border: 0;
    border-radius: 5px 0 0 5px;
    font-size: 11px;
    color: #ABA9A7;
    padding: 0 0 0 10px;

    @media (max-width: 500px) {
        height: 50px;
    }
`;
