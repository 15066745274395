import React, {
  useState, useCallback, useRef,
} from 'react';

import * as Yup from 'yup';

import { FiFrown } from 'react-icons/fi';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import { useToast } from '../../../../hooks/toast';

import {
  Container, Content, PageTitle, ContainerHeaderGrid, ItemsContainer, SearchContainer, SearchMessage, HeaderName, MembersList, Grid, MemberName, DatesContainer,
} from './styles';

import api from '../../../../services/api';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';


interface MemberList {
    id: string;
    name: string;
}

interface FormData {
    initial_date: Date;
    final_date: Date;
}

const DashboardMembersRegistered: React.FC = () => {
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const [members, setMembers] = useState<MemberList[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async (data: FormData) => {
    try {
      setIsLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          initial_date: Yup.date().required('Obrigatório'),
          final_date: Yup.date().required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          initial_date,
          final_date,
        } = data;

        await api.get('users', {
          params: {
            inicial_date: initial_date,
            final_date,
          },
        }).then((response) => {
          setMembers(response.data);
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Deu um erro na hora de buscar esse período. Tenta de novo, por favor.',
      });
    }
  }, [addToast]);

  return (
    <Container>
      <PageTitle>
        <h1>Painel de matrículas</h1>
      </PageTitle>

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          initial_date: format(new Date(), 'yyyy-MM-dd'),
          final_date: format(new Date(), 'yyyy-MM-dd'),
        }}
      >
        <DatesContainer>
          <Input name="initial_date" type="date" />
          <Input name="final_date" type="date" />
        </DatesContainer>


        <Button
          type="submit"
          style={{
            width: 200,
          }}
        >
          Buscar

        </Button>


      </Form>

      <Content>
        <ScaleLoader loading={isLoading} color="#FFFFFF" />
        {members.length !== 0 && !isLoading
          ? (
            <>
              <ContainerHeaderGrid>

                <ItemsContainer>
                  <HeaderName>NOME</HeaderName>
                </ItemsContainer>

              </ContainerHeaderGrid>
              <>
                {members.map((member) => (

                  <MembersList key={member.id}>

                    <Grid onClick={() => {}}>

                      <ItemsContainer>

                        <MemberName>
                          {member.name}
                        </MemberName>

                      </ItemsContainer>

                    </Grid>

                  </MembersList>

                ))}
              </>
              <Button>
                {members.length}
                {' '}
                alunos
              </Button>
            </>
          ) : (
            <>
            </>
          )}

        {members.length === 0 && !isLoading
          ? (
            <SearchContainer>
              <FiFrown />
              <SearchMessage>Não encontrei nenhum aluno</SearchMessage>
            </SearchContainer>
          ) : (
            <>
            </>
          )}
      </Content>
    </Container>
  );
};


export default DashboardMembersRegistered;
