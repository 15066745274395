/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';

import { FaTrash, FaPlus } from 'react-icons/fa';

import {
  Tab, Tabs, Typography, Grid, Box,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import { v4 as uuid } from 'uuid';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { zonedTimeToUtc } from 'date-fns-tz';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import api from '../../../../services/api';

import DialogBox from '../../../../components/DialogBox';
import InputLabel from '../../../../components/InputLabel';
import InputLabelDate from '../../../../components/InputLabelDate';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';

import {
  Container, Content, ContentForm, InputContainer, ActionsContainer, ActionsBox, ActionsCircle,
} from './styles';
// import { FiPlus } from 'react-icons/fi';

interface SignUpFormData {
    name: string;
    date_birth: string;
    cpf: string;
    email: string;
}

interface MyModalities {
    id: string;

    modality?: string | undefined;
    modality_id: string;

    class?: string | undefined;
    class_id: string;

    due_date?: string;
}

interface MyModalitiesFormData {
  user_id: string;
  modality_id: string;
  class_id: string;
  due_date?: string;
}

interface Modalities {
    id: string;
    title: string;
}

interface ClassTeam {
    id: string;
    title: string;
}

function TabPanel(props: any): any {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any): any {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const NewMember: React.FC = () => {
  const formMainRef = useRef<FormHandles>(null);
  const formModalityRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const history = useHistory();

  const [value, setValue] = useState(0);

  const [memberInfo, setMemberInfo] = useState<SignUpFormData>();

  const [modalities, setModalities] = useState<Modalities[]>([]);
  const [classTeam, setClassTeam] = useState<ClassTeam[]>([]);

  const [selectModality, setSelectModality] = useState<Modalities>();
  const [selectClassTeam, setSelectClassTeam] = useState<ClassTeam>();

  const [myModalities, setMyModalities] = useState<MyModalities[]>([]);

  const [boxIsOpen, setBoxIsOpen] = useState(false);

  useEffect(() => {
    api.get('/modality').then((response) => {
      const data: any = [];
      response.data.map((item: any) => {
        data.push({ value: item.id, label: item.title });
      });
      setModalities(data);
    });
  }, [selectModality]);

  const onChangeSelectModality = useCallback(async (event: any) => {
    setSelectModality({ id: event.value, title: event.label });

    api.get(`/class/${event.value}`).then((response) => {
      const data: any = [];
      response.data.map((item: any) => {
        data.push({ value: item.id, label: item.title });
      });
      setClassTeam(data);
    });
  }, []);

  const handleSaveBasicInformation = useCallback(async (nextTab) => {
    try {
      if (nextTab === 1) {
            formMainRef.current?.setErrors({});

            const allData = {
              name: formMainRef.current?.getFieldValue('name'), date_birth: formMainRef.current?.getFieldValue('date_birth'), cpf: formMainRef.current?.getFieldValue('cpf'), email: formMainRef.current?.getFieldValue('email'),
            };

            const schema = Yup.object().shape({
              name: Yup.string().required('Obrigatório'),
              date_birth: Yup.string().required('Obrigatório')
                .test('Date', 'O nascimento tem que ser maior que hoje', (item) => {
                  const input = format(new Date().setDate(new Date(item).getDate() + 1), 'dd/MM/yyyy');
                  const today = format(new Date(), 'dd/MM/yyyy');

                  if (input === today) { return; }

                  return item;
                }),
              cpf: Yup.string().required('Obrigatório'),
              email: Yup.string().required('Obrigatório').email('E-mail inválido'),
            });

            await schema.validate(allData, { abortEarly: false });

            setValue(nextTab);
            setMemberInfo(allData);
      } else {
        setValue(nextTab);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

          formMainRef.current?.setErrors(errors);
      }
    }
  }, []);

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
        formMainRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Obrigatório'),
          date_birth: Yup.date().required('Obrigatório'),
          cpf: Yup.number().required('Obrigatório'),
          email: Yup.string().required('Obrigatório').email('E-mail inválido'),
        });

        if (myModalities.length === 0) {
          addToast({
            type: 'info',
            title: 'Adicione uma modalidade e uma turma para prosseguir',
          });

          setMemberInfo(data);
          setValue(1);

          return;
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name, cpf, email, date_birth,
        } = data;

        const birthday = zonedTimeToUtc(date_birth, 'America/Sao_Paulo');
        const month_birth = format(birthday, 'MM');

        // Aqui nós transformamos o array em string (modalidades) para salvar as modalidades daquele aluno que serão utilizadas na listagem do check-in
        const modality = JSON.stringify(myModalities, ['modality_id', 'modality']);

        const formData = {
          name,
          date_birth,
          month_birth,
          cpf,
          email,
          password: cpf,
          permission: 'member',
          active: '0',
          modalities: modality,
        };

        const response = await api.post('/users', formData);
        const user = response.data;

        // Faz o tratamento do array de MODALIDADES para salvar no banco
        const myModalitiesFormData: MyModalitiesFormData[] = [];
        myModalities.map((item) => {
          const insert = {
            user_id: user.id, modality_id: item.modality_id, class_id: item.class_id, due_date: item.due_date,
          };

          myModalitiesFormData.push(insert);
        });

        await api.post('/myModalities', myModalitiesFormData);

        history.push('/dashboard-members');

        addToast({
          type: 'sucess',
          title: 'Aluno cadastrado',
          description: 'Agora ele já tem acesso à plataforma.',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formMainRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'data' in err.response ? err.response.data.message : 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
        // description: 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
      });
    }
  }, [addToast, history, myModalities]);

  const handleAddModality = useCallback(async (data: MyModalitiesFormData) => {
    try {
        formModalityRef.current?.setErrors({});

        const schema = Yup.object().shape({
          class_id: Yup.string().required('Obrigatório'),
          modality_id: Yup.string().required('Obrigatório'),
          due_date: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const { class_id, modality_id, due_date } = data;

        setMyModalities([...myModalities, {
          class_id, class: selectClassTeam?.title, modality_id, modality: selectModality?.title, due_date, id: uuid(),
        }]);

        setBoxIsOpen(false);

        addToast({
          type: 'sucess',
          title: 'Sucesso!',
          description: 'Modalidade cadastrada com sucesso',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formModalityRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao adicionar essa modalidade. Tenta de novo, por favor.',
      });
    }
  }, [myModalities, addToast, selectModality, selectClassTeam]);

  const handleRemoveModality = useCallback((id) => {
    const array = [...myModalities];

    const index = array.map((item) => item.id).indexOf(id);
    array.splice(index, 1);

    setMyModalities(array);
  }, [myModalities]);

  return (
    <Container>
      <Content>
        <h1>Novo aluno</h1>
        <Form
          ref={formMainRef}
          onSubmit={handleSubmit}
          initialData={{
            name: memberInfo ? memberInfo.name : '',
            date_birth: memberInfo ? memberInfo.date_birth : format(new Date(), 'yyyy-MM-dd'),
            cpf: memberInfo ? memberInfo.cpf : '',
            email: memberInfo ? memberInfo.email : '',
          }}
        >
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center',
          }}
          >
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={value}
              onChange={(event: any, nextTab: any): void => { handleSaveBasicInformation(nextTab); }}
              TabIndicatorProps={{
                style: { background: '#FCFE38' },
              }}
            >
              <Tab label="Informações" {...a11yProps(0)} style={{ outline: 'none' }} />
              <Tab label="Modalidades" {...a11yProps(1)} style={{ outline: 'none' }} />
            </Tabs>
            <div style={{ width: '100%' }}>
              <TabPanel value={value} index={0}>
                <Grid>
                  <InputContainer>
                    <InputLabel autoComplete="off" id="1" name="name" label="Nome" />
                  </InputContainer>

                  <InputContainer>
                    <InputLabelDate autoComplete="off" id="1" name="date_birth" label="Nascimento" type="date" />
                  </InputContainer>

                  <InputContainer>
                    <InputLabel autoComplete="off" id="1" name="cpf" label="CPF" type="number" />
                  </InputContainer>

                  <InputContainer>
                    <InputLabel autoComplete="off" id="1" name="email" label="E-mail" type="email" />
                  </InputContainer>
                  <Button type="submit">Gravar</Button>
                </Grid>

              </TabPanel>
              <TabPanel value={value} index={1}>
                <ActionsContainer>
                  <ActionsBox>
                    <ActionsCircle
                      onClick={() => setBoxIsOpen(true)}
                    >
                      <FaPlus />
                    </ActionsCircle>
                  </ActionsBox>
                </ActionsContainer>
                {myModalities.map((item) => (
                  <>
                    <InputContainer>
                      <InputLabel autoComplete="off" id="1" disabled name="" label="Modalidade" value={item.modality} style={{ color: '#FCFE38' }} />
                    </InputContainer>

                    <InputContainer>
                      <InputLabel autoComplete="off" id="1" disabled name="" label="Turma" value={item.class} />
                    </InputContainer>

                    <InputContainer>
                      <InputLabel autoComplete="off" id="1" disabled name="" label="Vencimento" type="date" value={item.due_date} />
                    </InputContainer>

                    <ActionsContainer>
                      <ActionsBox>
                        {/* <ActionsCircle
                          style={{ background: '#0396C4' }}
                        >
                          <FaInfo />
                        </ActionsCircle> */}

                        <ActionsCircle
                          onClick={() => { handleRemoveModality(item.id); }}
                        >
                          <FaTrash />
                        </ActionsCircle>
                      </ActionsBox>
                    </ActionsContainer>
                  </>
                ))}

                {/* <Button onClick={() => setBoxIsOpen(true)}>Nova modalidade</Button> */}
              </TabPanel>
            </div>
          </div>
        </Form>
      </Content>

      <DialogBox
        open={boxIsOpen}
        onClose={(): void => setBoxIsOpen(false)}
      >
        <ContentForm>
          <Form
            ref={formModalityRef}
            onSubmit={handleAddModality}
            initialData={{
              due_date: format(new Date(), 'yyyy-MM-dd'),
            }}
          >
            <h1 style={{ marginBottom: 20 }}>Nova modalidade</h1>
            <Grid>
              <InputContainer>
                <Select
                  placeholder="Modalidade..."
                  name="modality_id"
                  options={modalities}
                  onChange={(event: any) => { onChangeSelectModality(event); }}
                />
              </InputContainer>

              <InputContainer>
                <Select
                  placeholder="Turma..."
                  name="class_id"
                  options={classTeam}
                  onChange={(event: any) => { setSelectClassTeam({ id: event.value, title: event.label }); }}
                />
              </InputContainer>

              <InputContainer>
                <InputLabelDate autoComplete="off" id="1" name="due_date" label="Vencimento" type="date" />
              </InputContainer>

              <Button type="submit">Adicionar</Button>
            </Grid>
          </Form>
        </ContentForm>
      </DialogBox>
    </Container>
  );
};

export default NewMember;
