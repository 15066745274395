import React, {
  useState, useEffect,
} from 'react';

import { FiFrown } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import ScaleLoader from 'react-spinners/ScaleLoader';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import api from '../../../services/api';

import {
  Container, Content, PageTitle, SearchContainer, Day, SearchMessage, DayContainer, ChangeClassDescription, ChangeClass,
} from './styles';

interface WodsList {
    id: string;
    date: string;
    modality_id: string;
    title: string;
    runs: string;

    MODALITY: {
      title: string;
    };
}

const DashboardWodsMembers: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const history = useHistory();

  const [wods, setWods] = useState<WodsList[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      api.get(`/wod/${user.modalities}`).then((response) => {
        setWods(response.data);
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Ocorreu um erro ao carregar a lista de WODs. Por favor, saia da página e retorne para uma nova tentativa.',
      });
    }
  }, [addToast]);

  return (
    <Container>

      <PageTitle>
        <h1>Treinos</h1>
      </PageTitle>


      <Content>
        <ScaleLoader loading={isLoading} color="#FFFFFF" />

        {wods.length !== 0 && !isLoading ? (
          <>
            {wods.map((item) => (
              <DayContainer
                key={item.id}
                onClick={() => {
                  history.push({
                    pathname: '/details-wod',
                    state: {
                      wod_id: item.id, date: item.date, modality_id: item.modality_id, modality: item.MODALITY.title, title: item.title, runs: item.runs,
                    },
                  });
                }}
              >
                <Day>
                  <h1>{item.MODALITY.title}</h1>
                  {/* <h2>{item.title}</h2> */}
                  <h3>{item.title}</h3>
                </Day>
              </DayContainer>
            ))}
          </>
        ) : (
          <>
          </>
        )}

        {wods.length === 0 && !isLoading ? (
          <SearchContainer>
            <FiFrown />
            <SearchMessage>Não encontrei nenhum WOD nas suas modalidades</SearchMessage>
          </SearchContainer>
        ) : (
          <>

          </>
        )}


      </Content>
    </Container>
  );
};


export default DashboardWodsMembers;
