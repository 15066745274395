import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
padding: 0 5px;
`;

export const Content = styled.main`
    margin: 20px auto;
    display: flex;
    place-content: center;
    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
        color: #c5c5c5;
        font-size: 17px;
        font-weight: 400;
        margin-top: 10px;
    }
`;

export const Option = styled.div`
    background: rgba(230, 232, 74, 0.41);
    height: 56px;
    border-radius: 3px;
    border: 2px solid #FCFE38;
    padding: 0 16px;
    color: #FCFE38;
    width: 200px;
    font-weight: bold;
    transition: background-color 0.2s;

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;

    &:hover {
        /* background: ${shade(0.5, '#FCFE38')}; */
        cursor: pointer;
    }
`;
