import React from 'react';
// import {
//   FiXCircle,
// } from 'react-icons/fi';
import { Container, Overlay } from './styles';

interface Props {
    open?: boolean;
    onClose?(): void;
}

const DialogBox: React.FC<Props> = ({
  children, open, onClose, ...rest
}) => {
  if (!open) return null;
  return (
    <>
      <Overlay
        onClick={onClose}
      />
      <Container {...rest}>

        {/* <FiXCircle
          onClick={onClose}
        /> */}

        <div>{children}</div>
      </Container>
    </>
  );
};

export default DialogBox;
