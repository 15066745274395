/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, {
  useState, useCallback, useEffect, forwardRef, useRef,
} from 'react';
import {
  FiUser, FiCalendar, FiClock, FiPhone, FiBookmark, FiFrown,
} from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import ScaleLoader from 'react-spinners/ScaleLoader';

import * as Yup from 'yup';
import getValidationErrors from '../../../../../utils/getValidationErrors';

import {
  Container, Content, Description, Day, TitleClass, Time, DayContainer, ListTitle, ItemsCardContainer, SearchContainer, SearchMessage,
} from './styles';


import DialogBox from '../../../../../components/DialogBox';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';

import api from '../../../../../services/api';

import { useToast } from '../../../../../hooks/toast';


interface ClassList {
    modalityId: string;
    modalityName: string;
}

interface Person {
    id: string;
    name: string;
    contact: string;

    modality_reference: {
        title: string;
    };
    date: string;
    dateBra?: string;
    time: string;
}

interface FormData {
    name: string;
    contact: string;
    date: string;
    time: string;
}

const DashboardExperimentalList = forwardRef(
  // ATIVAR MÉTODO FILHO: passo 7 (fazer o recebimento dessa referência)
  (props: ClassList) => {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);

    const [persons, setPersons] = useState<Person[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<Person>();
    const [boxIsOpen, setBoxIsOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const loadPanel = useCallback(() => {
      try {
        api.get(`/waitexperimental/experimental/${props.modalityId}`).then((response) => {
          response.data.map((item: Person) => {
            const splitedDate = item.date.split('-');
            item.dateBra = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
          });
          setPersons(response.data);
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ;(',
          description: 'Ocorreu um erro ao carregar a lista de alunos. Por favor, saia da página e retorne para uma nova tentativa.',
        });
      }
    }, [addToast, props.modalityId]);

    useEffect(() => {
      loadPanel();
    }, [loadPanel]);

    const handleDelete = useCallback(async (id: string | undefined) => {
      try {
        await api.delete(`waitexperimental/${id}`);

        loadPanel();

        setBoxIsOpen(false);

        addToast({
          type: 'sucess',
          title: 'Aluno removido com sucesso',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ;(',
          description: 'Deu um erro quando tentei excluir esse aluno. Tenta de novo, por favor.',
        });
      }
    }, [addToast, loadPanel]);

    const handleSelectPerson = useCallback(async (id: string, name: string, contact: string, modality: string, date: string, time: string) => {
      const values = {
        id, name, contact, modality_reference: { title: modality }, date, time,
      };

      setSelectedPerson(values);

      setBoxIsOpen(true);
    }, []);

    const handleSubmit = useCallback(async (data: FormData) => {
      try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              name: Yup.string().required('Obrigatório'),
              contact: Yup.string().required('Obrigatório'),
              date: Yup.string().required('Obrigatório'),
              time: Yup.string().required('Obrigatório'),
            });

            await schema.validate(data, {
              abortEarly: false,
            });

            const {
              name,
              contact,
              date,
              time,
            } = data;

            const formData = {
              name,
              time,
              contact,
              date,
            };

            await api.put(`/waitexperimental/${selectedPerson?.id}`, formData);

            loadPanel();

            setBoxIsOpen(false);

            addToast({
              type: 'sucess',
              title: 'Sucesso',
              description: 'Aluno experimental atualizado com sucesso.',
            });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

            formRef.current?.setErrors(errors);

            return;
        }

        addToast({
          type: 'info',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
        });
      }
    }, [addToast, selectedPerson]);


    return (
      <Container>
        <Content>
          <DayContainer>
            <Day>
              <h1>{props.modalityName}</h1>
            </Day>

            {isLoading ? <ScaleLoader loading={isLoading} color="#FFFFFF" />
              : (
                <>
                  {persons.map((person) => (
                    <Description
                      onClick={() => { handleSelectPerson(person.id, person.name, person.contact, person.modality_reference.title, person.date, person.time); }}
                    >
                      <TitleClass>
                        {person.name}
                      </TitleClass>

                      <Time>
                        {person.dateBra}
                        {' '}
                        às
                        {' '}
                        {person.time}
                      </Time>
                    </Description>
                  ))}
                </>
              )}

            {persons.length === 0 && !isLoading ? (
              <>
                <SearchContainer>
                  <FiFrown />
                  <SearchMessage>Nenhum aluno na lista</SearchMessage>
                </SearchContainer>
              </>
            ) : (
              <></>
            )}

          </DayContainer>

          <DialogBox
            open={boxIsOpen}
            onClose={() => setBoxIsOpen(false)}
          >
            <ListTitle style={{ fontSize: 25 }}>Aluno experimental</ListTitle>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                name: selectedPerson?.name,
                contact: selectedPerson?.contact,
                modality: selectedPerson?.modality_reference.title,
                date: selectedPerson?.date,
                time: selectedPerson?.time,

              }}
            >
              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input name="name" icon={FiUser} />
              </ItemsCardContainer>

              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input name="contact" icon={FiPhone} />
              </ItemsCardContainer>

              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input name="modality" disabled icon={FiBookmark} />
              </ItemsCardContainer>

              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input type="date" name="date" icon={FiCalendar} />
              </ItemsCardContainer>

              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input type="time" name="time" icon={FiClock} />
              </ItemsCardContainer>

              <Button
                style={{ width: '100%' }}
                type="submit"
              >
                Salvar

              </Button>

              <Button
                style={{ width: '100%' }}
                onClick={() => { handleDelete(selectedPerson?.id); }}
              >
                Excluir

              </Button>
            </Form>
          </DialogBox>
        </Content>
      </Container>
    );
  },
);


export default DashboardExperimentalList;
