import React, {
  useState, useEffect,
} from 'react';

import { FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Container, Content, PageTitle, Actions, AddButton,
} from './styles';

import api from '../../../../../services/api';

import { useToast } from '../../../../../hooks/toast';

import ClassesList from '../Dashboard Waiting List/index';

interface Modality {
    id: string;
    title: string;
}

const DashboardWaitingListMain: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const [modalities, setModalities] = useState<Modality[]>([]);

  useEffect(() => {
    try {
      api.get('/modality').then((response) => {
        setModalities(response.data);
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Ocorreu um erro ao carregar a lista de modalidades. Por favor, saia da página e retorne para uma nova tentativa.',
      });
    }
  }, [addToast]);

  return (
    <Container>

      <PageTitle>
        <h1>Lista de espera</h1>
      </PageTitle>

      <Actions>
        <AddButton>
          <FiPlus
            onClick={() => history.push('/new-person')}
          />
        </AddButton>
      </Actions>

      <Content>
        {modalities.map((modality) => (
          <ClassesList
            key={modality.id}
            modalityId={modality.id}
            modalityName={modality.title}
          />
        ))}
      </Content>
    </Container>
  );
};


export default DashboardWaitingListMain;
