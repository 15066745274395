import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';
import {
  LocationOn, Home, Event, Schedule, GpsFixed, Groups, AttachMoney, FitnessCenter, Label, Check, HourglassBottom, Cake, ThumbsUpDown, DirectionsRun,
} from '../../styles/Icons';

const iconsCSS = css`
    flex-shrink: 0;
    width: 31px;
    height: 31px;

    color: #FFF;
`;

export const HomeIcon = styled(Home)`${iconsCSS}`;
export const CheckinIcon = styled(LocationOn)`${iconsCSS}`;
export const WodIcon = styled(FitnessCenter)`${iconsCSS}`;
export const TimeBoardIcon = styled(Schedule)`${iconsCSS}`;
export const PlansIcon = styled(AttachMoney)`${iconsCSS}`;
export const EventIcon = styled(Event)`${iconsCSS}`;
export const WoWeAreIcon = styled(Groups)`${iconsCSS}`;
export const MissionIcon = styled(GpsFixed)`${iconsCSS}`;

export const ClassIcon = styled(Label)`${iconsCSS}`;
export const EnrolledMemberIcon = styled(Check)`${iconsCSS}`;
export const WaitingListIcon = styled(HourglassBottom)`${iconsCSS}`;
export const ExperimentalMemberIcon = styled(ThumbsUpDown)`${iconsCSS}`;
export const BirthdayIcon = styled(Cake)`${iconsCSS}`;
export const TeachIcon = styled(DirectionsRun)`${iconsCSS}`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const Container = styled.div`
    display: none;

    background: #28262E;

    img {
        width: 50px;
        height: 50px;

        align-self: center;

        margin-bottom: 15px;
    }

    @media (min-width: 500px) {
        display: flex;
        flex-direction: column;

        position: sticky;
        top: 0;
        left: 0;

        padding: 9px 5px 20px;

        height: 100vh;
        max-height: 100vh;
    }


`;

export const Option = styled.div`
    height: 60px;
    background: #28262E;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-shrink: 0;


    > span {
            display: none;
        }

    &:hover,
    &.active {
        svg {
        fill: #0B7FE3;
        }
    }



    @media (min-width: 1280px) {
        justify-content: flex-start;
        width: 210px;

        > span {
            display: inline;
            font-size: 13px;
            margin-left: 20px;
        }
    }

    &:hover {
        background: ${shade(0.2, '#28262E')};
        cursor: pointer;
        border-radius: 7px;
    }
`;
