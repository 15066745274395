/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';

import { Grid } from '@material-ui/core';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import api from '../../../../services/api';

import InputLabel from '../../../../components/InputLabel';
import InputLabelDate from '../../../../components/InputLabelDate';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Textarea from '../../../../components/Textarea';


import { Container, Content, InputContainer } from './styles';

interface FormData {
    start_date: string;
    date: string;
    modality_id: string;
    title: string;
    runs: string;
}

interface Modalities {
    id: string;
    title: string;
}

const NewMember: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const history = useHistory();

  const [modalities, setModalities] = useState<Modalities[]>([]);

  useEffect(() => {
    api.get('/modality').then((response) => {
      const data: any = [];
      response.data.map((item: any) => {
        data.push({ value: item.id, label: item.title });
      });
      setModalities(data);
    });
  }, []);

  const handleSubmit = useCallback(async (data: FormData) => {
    try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          start_date: Yup.string().required('Obrigatório'),
          date: Yup.string().required('Obrigatório'),
          modality_id: Yup.string().required('Obrigatório'),
          title: Yup.string().required('Obrigatório'),
          runs: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          start_date, date, modality_id, title, runs,
        } = data;

        const formData = {
          start_date,
          date,
          modality_id,
          title,
          runs,
        };

        await api.post('/wod', formData);

        addToast({
          type: 'sucess',
          title: 'Sucesso',
          description: 'WOD cadastrado com sucesso!',
        });

        history.push('/wods');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
      });
    }
  }, [addToast]);


  return (
    <Container>
      <Content>
        <h1>Novo WOD</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            start_date: format(new Date(), 'yyyy-MM-dd'),
            date: format(new Date(), 'yyyy-MM-dd'),
          }}
        >
          <Grid>
            <InputContainer>
              <InputLabelDate autoComplete="off" id="1" name="start_date" label="Início" type="date" />
            </InputContainer>

            <InputContainer>
              <InputLabelDate autoComplete="off" id="1" name="date" label="Fim" type="date" />
            </InputContainer>

            <InputContainer>
              <Select
                placeholder="Modalidade..."
                name="modality_id"
                options={modalities}
                // onChange={(event: any) => { setSelectModality({ id: event.value, title: event.label }); }}
              />
            </InputContainer>

            <InputContainer>
              <InputLabel autoComplete="off" id="1" name="title" label="Título" />
            </InputContainer>

            <InputContainer>
              <Textarea autoComplete="off" id="1" name="runs" label="Exercícios" />
            </InputContainer>
            <Button type="submit">Gravar</Button>
          </Grid>
        </Form>
      </Content>
    </Container>
  );
};

export default NewMember;
