import React from 'react';

import {
  Container,
  Content,
  PageTitle,
  Description,
  Plan,
  TitlePlan,
  PlanContainer,
  Payment,
  PaymentDescription,
} from './styles';

import { options } from './options';

const Contracts: React.FC = () => (
  <Container>
    <PageTitle>
      <h1>Planos</h1>
    </PageTitle>

    <Content>

      <PlanContainer>
        {options.map((item) => (
          <>
            <Plan>
              <h1>{item.title}</h1>
              <h2>{item.frequency}</h2>
              {item.subtitle && <h3>{item.subtitle}</h3>}
            </Plan>

            {item.plans.yearly && (
            <Description>
              <TitlePlan>
                12 meses:
              </TitlePlan>
              <h1>
                {item.plans.yearly}
              </h1>
            </Description>
            )}

            {item.plans.semester && (
            <Description>
              <TitlePlan>
                06 meses:
              </TitlePlan>
              <h1>
                {item.plans.semester}
              </h1>
            </Description>
            )}

            {item.plans.quarterly && (
            <Description>
              <TitlePlan>
                03 meses:
              </TitlePlan>
              <h1>
                {item.plans.quarterly}
              </h1>
            </Description>
            )}

            {item.plans.monthly && (
            <Description>
              <TitlePlan>
                Mensal:
              </TitlePlan>
              <h1>
                {item.plans.monthly}
              </h1>
            </Description>
            )}

          </>
        ))}
      </PlanContainer>

      <PlanContainer>
        <Payment>
          <h1>IMPORTANTE</h1>
          <h2>Planos de 03, 06 e 12 meses</h2>
        </Payment>
        <PaymentDescription>
          <TitlePlan>
            Formas de pagamento
          </TitlePlan>
          <h1>
            Boleto ou cartão
          </h1>
        </PaymentDescription>

        <PaymentDescription>
          <TitlePlan>
            Desconto
          </TitlePlan>
          <h1>
            10% de desconto para pagamentos à vista, no dinheiro, para os planos acima de 3 meses.
          </h1>
        </PaymentDescription>
      </PlanContainer>

    </Content>
  </Container>
);


export default Contracts;
