import React, { useCallback, useRef } from 'react';

import { format } from 'date-fns';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {
  Container, Content, InputContainer, TagInput,
} from './styles';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

interface FormData {
    title: string;
    date: string;
    time: string;
    space: number; // Número máximo de alunos permitidos nessa aula (vagas)
}

interface Modalities {
    id: string;
    title: string | null;
}

const NewEvent: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(async (data: FormData) => {
    try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          date: Yup.date().required('Obrigatório'),
          time: Yup.string().required('Obrigatório'),
          title: Yup.string().required('Obrigatório'),
          space: Yup.number().required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          title,
          date,
          time,
          space,
        } = data;

        const formData = {
          modality: title,
          date,
          time,
          space,
          occupied_spaces: 0,
          type: 'Evento',
        };

        await api.post('/teach', formData);

        history.push('/events');

        addToast({
          type: 'sucess',
          title: 'Sucesso',
          description: 'Esse evento já está cadastrado pra você.',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
      });
    }
  }, [addToast, history]);

  return (
    <Container>
      <Content>
        <h1>Novo evento</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            date: format(new Date(), 'yyyy-MM-dd'),
            time: format(new Date(), 'HH:mm'),
          }}
        >
          <InputContainer>
            <TagInput>Título: </TagInput>
            <Input name="title" />
          </InputContainer>

          <InputContainer>
            <TagInput>Data: </TagInput>
            <Input name="date" type="date" />

          </InputContainer>

          <InputContainer>
            <TagInput>Horário: </TagInput>
            <Input name="time" type="time" />
          </InputContainer>

          <InputContainer>
            <TagInput>Vagas: </TagInput>
            <Input name="space" type="number" />
          </InputContainer>

          <Button type="submit">Cadastrar</Button>

        </Form>
      </Content>
    </Container>
  );
};


export default NewEvent;
