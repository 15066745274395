import React from 'react';

import {
  Container, Content, PageTitle, Description,
} from './styles';

import { useAuth } from '../../../hooks/auth';


const WhoWeAre: React.FC = () => (
  <Container>
    <PageTitle>
      <h1>Missão</h1>
    </PageTitle>


    <Content>

      <Description>
        Nossa missão é promover saúde, através do exercício físico e relacionamento humano.
      </Description>

    </Content>
  </Container>
);


export default WhoWeAre;
