import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';

import {
  formatDistance,
} from 'date-fns';

import pt from 'date-fns/locale/pt-BR';
import { RouteProps as ReactDOMRouteProps, Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';

import Layout from '../components/Layout';

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ isPrivate = false, component: Component, ...rest }) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  //   const role = (user?.permission === 'ADM');

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (user && isPrivate) {
          return (
            <>
              <Layout>
                <Component />
              </Layout>
            </>
          );
        }

        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/home',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
