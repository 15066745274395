export const options = [
  // FUNCIONAL
  {
    title: 'FUNCIONAL',
    frequency: '(3x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 150,00',
      semester: 'R$  170,00',
      quarterly: 'R$ 190,00',
      monthly: 'R$ 210,00',
    },
  },
  {
    title: 'FUNCIONAL',
    frequency: '(2x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 115,00',
      semester: 'R$ 135,00',
      quarterly: 'R$ 155,00',
      monthly: 'R$ 175,00',
    },
  },
  {
    title: 'FUNCIONAL',
    frequency: '(1x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 95,00',
      semester: 'R$ 115,00',
      quarterly: 'R$ 135,00',
      monthly: 'R$ 155,00',
    },
  },

  // BIKE
  {
    title: 'BIKE',
    frequency: '(2x semana)',
    subtitle: 'Somente para pessoas que já são alunas 2LIFE de outras modalidades',
    plans: {
      yearly: 'R$ 105,00',
      semester: 'R$ 125,00',
      quarterly: 'R$ 145,00',
      monthly: 'R$ 165,00',
    },
  },
  {
    title: 'BIKE',
    frequency: '(2x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 115,00',
      semester: 'R$ 135,00',
      quarterly: 'R$ 155,00',
      monthly: 'R$ 175,00',
    },
  },
  {
    title: 'BIKE',
    frequency: '(1x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 95,00',
      semester: 'R$ 115,00',
      quarterly: 'R$ 135,00',
      monthly: 'R$ 155,00',
    },
  },

  // LPO
  {
    title: 'LPO',
    frequency: '(3x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 170,00',
      semester: 'R$ 190,00',
      quarterly: 'R$ 210,00',
      monthly: 'R$ 230,00',
    },
  },
  {
    title: 'LPO',
    frequency: '(2x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 150,00',
      semester: 'R$ 170,00',
      quarterly: 'R$ 190,00',
      monthly: 'R$ 210,00',
    },
  },
  {
    title: 'LPO',
    frequency: '(1x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 125,00',
      semester: 'R$ 145,00',
      quarterly: 'R$ 165,00',
      monthly: 'R$ 185,00',
    },
  },

  // PLANOS ESPECIAIS
  {
    title: 'PLANO PRATA',
    frequency: '(4x semana)',
    subtitle: 'Acesso a todas modalidades, exceto LPO',
    plans: {
      yearly: 'R$ 185,00',
      semester: 'R$ 205,00',
      quarterly: 'R$ 225,00',
      monthly: null,
    },
  },
  {
    title: 'PLANO OURO',
    frequency: '(5x semana)',
    subtitle: 'Acesso a todas modalidades, exceto LPO',
    plans: {
      yearly: 'R$ 225,00',
      semester: 'R$ 245,00',
      quarterly: 'R$ 265,00',
      monthly: null,
    },
  },

  // KIDS
  {
    title: 'KIDS',
    frequency: '(2x semana)',
    subtitle: null,
    plans: {
      yearly: 'R$ 130,00',
      semester: 'R$ 140,00',
      quarterly: 'R$ 145,00',
      monthly: 'R$ 150,00',
    },
  },
  {
    title: 'FUNCIONAL KIDS',
    frequency: '(2x semana)',
    subtitle: 'Plano Família',
    plans: {
      yearly: 'R$ 120,00',
      semester: 'R$ 130,00',
      quarterly: 'R$ 135,00',
      monthly: 'R$ 140,00',
    },
  },
];
