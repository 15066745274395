import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';

import { FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';


import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useToast } from '../../../../hooks/toast';

import {
  Container, Content, PageTitle, AddButton,
} from './styles';

import api from '../../../../services/api';

import Table from '../../../../components/TableMember';

  interface WodsList {
      id: string;
      start_date: string;
      date: string;
      modality_id: string;
      title: string;
      runs: string;

      MODALITY: {
        title: string;
      };
  }


const DashboardMembers: React.FC = () => {
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [wods, setWods] = useState<WodsList[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      api.get('/wod').then((response) => {
        setWods(response.data);
      });

      setIsLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Ocorreu um erro ao carregar a lista de WODs. Por favor, saia da página e retorne para uma nova tentativa.',
      });
    }
  }, [addToast]);


  const handleDelete = useCallback(async (id: string) => {
    try {
      await api.delete(`wod/${id}`);

      api.get('/wod').then((response) => {
        setWods(response.data);
      });

      addToast({
        type: 'sucess',
        title: 'WOD removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Deu um erro quando tentei excluir esse WOD. Tenta de novo, por favor.',
      });
    }
  }, [addToast]);

  return (
    <Container>
      <PageTitle>
        <h1>WODs</h1>
      </PageTitle>

      <Form ref={formRef} onSubmit={() => {}}>
        <AddButton>
          <FiPlus
            onClick={() => history.push('/new-wod')}
            style={{
              width: 40, height: 40, color: '#FCFE38',
            }}
          />
        </AddButton>
      </Form>

      <Content>
        <Table
          columns={[
            { title: 'MODALIDADE', field: 'MODALITY.title' },
            { title: 'TÍTULO', field: 'title' },
            { title: 'VIGÊNCIA', field: 'date' },
          ]}
          data={wods}
          loader={isLoading}
          actions={[
            {
              icon: 'create',
              iconProps: { style: { color: '#8F8F8F' } },
              tooltip: 'Editar',
              onClick: (event: any, row: WodsList): void => {
                history.push({
                  pathname: '/update-wod',
                  state: {
                    wod_id: row.id, start_date: row.start_date, date: row.date, modality_id: row.modality_id, modality: row.MODALITY.title, title: row.title, runs: row.runs,
                  },
                });
              },
            },
            {
              icon: 'delete',
              iconProps: { style: { color: '#FCFE38' } },
              tooltip: 'Excluir',
              onClick: (event: any, row: WodsList): void => {
                handleDelete(row.id);
              },
            },
          ]}
        />
      </Content>
    </Container>
  );
};


export default DashboardMembers;
