/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, {
  useState, useCallback, useEffect, forwardRef,
} from 'react';
import { FiFrown } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import ScaleLoader from 'react-spinners/ScaleLoader';

import {
  Container, Content, Day, DayContainer, CardsContainer, Card, ItemsCardContainer, DateLabel, DateValue, OthersLabel, OthersValue, SearchContainer, SearchMessage,
} from './styles';

import { useAuth } from '../../../../../hooks/auth';


import api from '../../../../../services/api';

import { useToast } from '../../../../../hooks/toast';

interface ClassList {
    modalityId: string;
    modalityName: string;
}

interface Teach {
    id: string;
    modality: string;
    modality_id: string;
    date: string;
    dateISO?: string;
    time: string;
    coach: string;
    space: string;
    occupied_spaces: string;
    type: string;
}

const DashboardTeachGroupsList = forwardRef(
  // ATIVAR MÉTODO FILHO: passo 7 (fazer o recebimento dessa referência)
  (props: ClassList) => {
    const { addToast } = useToast();
    const history = useHistory();
    const { user } = useAuth();

    const [teachs, setTeachs] = useState<Teach[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadPanel = useCallback(() => {
      try {
        const formattedData: Teach[] = [];

        api.get(`/teach/${props.modalityId}/${user.permission}`).then((response) => {
          response.data.map((item: any) => {
            const formattedDate = zonedTimeToUtc(item.date, 'America/Sao_Paulo');
            item.date = format(formattedDate, "dd'/'MM'/'yyyy");
            item.dateISO = format(formattedDate, "yyyy'-'MM'-'dd");

            formattedData.push(item);
          });

          setTeachs(formattedData);
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ;(',
          description: 'Ocorreu um erro ao carregar as aulas. Por favor, saia da página e retorne para uma nova tentativa.',
        });
      }
    }, [addToast, props.modalityId, user.permission]);

    useEffect(() => {
      loadPanel();
    }, [loadPanel]);

    return (
      <Container>
        <Content>
          <DayContainer>
            <Day>
              <h1>{props.modalityName}</h1>
            </Day>
            <CardsContainer>
              <ScaleLoader loading={isLoading} color="#FFFFFF" />

              {teachs.length !== 0 && !isLoading ? (
                <>
                  {teachs.map((item) => (
                    <Card
                      onClick={() => {
                        history.push({
                          pathname: '/teach-check',
                          state: {
                            id: item.id, title: item.modality, date: item.date, dateISO: item.dateISO, time: item.time, coach: item.coach, space: item.space,
                          },
                        });
                      }}
                    >
                      <ItemsCardContainer>
                        <DateLabel>Data:</DateLabel>

                        <DateValue>{item.date}</DateValue>
                      </ItemsCardContainer>

                      <ItemsCardContainer>
                        <OthersLabel>Horário:</OthersLabel>

                        <OthersValue>{item.time}</OthersValue>
                      </ItemsCardContainer>

                      <ItemsCardContainer>
                        <OthersLabel>Coach:</OthersLabel>

                        <OthersValue>{item.coach}</OthersValue>
                      </ItemsCardContainer>

                      <ItemsCardContainer>
                        <OthersLabel>Confirmados:</OthersLabel>

                        <OthersValue>
                          {item.occupied_spaces}
                          /
                          {item.space}
                        </OthersValue>
                      </ItemsCardContainer>
                    </Card>
                  ))}
                </>
              ) : (
                <>
                </>
              )}

              {teachs.length === 0 && !isLoading ? (
                <SearchContainer>
                  <FiFrown />
                  <SearchMessage>Nenhuma aula nessa modalidade</SearchMessage>
                </SearchContainer>
              ) : (
                <>
                </>
              )}
            </CardsContainer>
          </DayContainer>
        </Content>
      </Container>
    );
  },
);


export default DashboardTeachGroupsList;
