import React, {
  useCallback, useRef, useState, useEffect, ChangeEvent,
} from 'react';

import 'date-fns';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {
  Container, Content, InputContainer, TagInput, SelectContainer, DateTimeInputContainer,
} from './styles';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

interface FormData {
    modality: string;
    modality_id: string;
    date: string;
    time: string;
    coach: string;
    space: number; // Número máximo de alunos permitidos nessa aula (vagas)
    type: string; // Aula ou evento?
}

interface Modalities {
    id: string;
    title: string | null;
}

const NewTeachGroups: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [modalities, setModalities] = useState<Modalities[]>([]);
  const [selectModality, setSelectModality] = useState<Modalities>();

  //   const [date, setDate] = useState('');
  //   const [time, setTime] = useState('');

  useEffect(() => {
    api.get('/modality').then((response) => setModalities(response.data));
  }, [selectModality]);

  // Captura o ID e o TEXTO da modalidade selecionada e salva em um estado de objeto tipado
  const onChangeSelectModality = useCallback(async (event: ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    const title = event.target.children[event.target.selectedIndex].textContent;

    const union: Modalities = { id, title };
    setSelectModality(union);
  }, []);


  const handleSubmit = useCallback(async (data: FormData) => {
    try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          coach: Yup.string().required('Obrigatório'),
          space: Yup.number().required('Obrigatório'),
          date: Yup.date().required('Obrigatório'),
          time: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          coach,
          space,
          date,
          time,
        } = data;

        const formData = {
          modality: selectModality?.title,
          modality_id: selectModality?.id,
          date,
          time,
          coach,
          space,
          occupied_spaces: 0,
          type: 'Aula',
        };

        await api.post('/teach', formData);

        history.push('/teach-groups');

        addToast({
          type: 'sucess',
          title: 'Sucesso',
          description: 'Essa aula já está cadastrada pra você.',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
      });
    }
  }, [addToast, history, selectModality]);

  return (
    <Container>
      <Content>
        <h1>Nova aula</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            date: format(new Date(), 'yyyy-MM-dd'),
            time: format(new Date(), 'HH:mm'),
          }}
        >
          <InputContainer>
            <TagInput>Modalidade: </TagInput>
            <SelectContainer>
              <select name="modality_id" id="modality" onChange={onChangeSelectModality}>
                <option value="0">Selecione...</option>
                {modalities.map((teach) => (
                  <option key={teach.id} value={teach.id}>{teach.title}</option>
                ))}
              </select>
            </SelectContainer>
          </InputContainer>

          <InputContainer>
            <TagInput>Data: </TagInput>
            <Input name="date" type="date" />
            {/* <DateTimeInputContainer>
              <input name="date" type="date" placeholder="yyyy-mm-dd" onChange={(event: any) => { setDate(event.target.value); }} />
            </DateTimeInputContainer> */}
          </InputContainer>

          <InputContainer>
            <TagInput>Horário: </TagInput>
            <Input name="time" type="time" />
            {/* <DateTimeInputContainer>
              <input name="time" type="time" placeholder="HH:MM" onChange={(event: any) => { setTime(event.target.value); }} />
            </DateTimeInputContainer> */}
          </InputContainer>

          <InputContainer>
            <TagInput>Coach: </TagInput>
            <Input name="coach" />
          </InputContainer>

          <InputContainer>
            <TagInput>Vagas: </TagInput>
            <Input name="space" type="number" />
          </InputContainer>

          <Button type="submit">Cadastrar</Button>

        </Form>
      </Content>
    </Container>
  );
};


export default NewTeachGroups;
