import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';

import { zonedTimeToUtc } from 'date-fns-tz';

import {
  FiFrown, FiTrash2,
} from 'react-icons/fi';

import { useHistory } from 'react-router-dom';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { format } from 'date-fns';
import { useToast } from '../../../../hooks/toast';

import {
  Container, Content, PageTitle, BoxTable, ContainerHeaderGrid, HeaderGrid, ItemsContainer, SearchContainer, SearchMessage, HeaderName, HeaderDueDate, MembersList, Grid, MemberName, MemberDueDate, ClassTitle, AddButton,
} from './styles';

import api from '../../../../services/api';
import Table from '../../../../components/TableMember';
import Button from '../../../../components/Button';

interface MemberList {
    user_id: string;
    due_date: Date;
    USER: {
        name: string;
        due_date: Date;
    };
    MODALITY: {
        title: string;
    };
    CLASS: {
        title: string;
    };
}


const MembersbyClass: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const [members, setMembers] = useState<MemberList[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const class_name = localStorage.getItem('className');
  const class_id = localStorage.getItem('classId');
  const modality_name = localStorage.getItem('modalityName');
  const modality_id = localStorage.getItem('modalityId');

  useEffect(() => {
    try {
      api.get('/myModalities', {
        params: {
          modality_id,
          class_id,
        },
      }).then((response) => {
        setMembers(response.data);
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Ocorreu um erro ao carregar a lista de alunos dessa modalidade. Por favor, saia da página e retorne para uma nova tentativa.',
      });
    }
  }, [addToast, modality_id, class_id]);

  // Percorre todos os alunos encontrados e formata a data de uma forma amigável para o usuário
  const runMembers = useMemo(() => members
    .map((member) => {
      const id = member.user_id;
      const { name } = member.USER;
      const modality = member.MODALITY.title;
      const classInitial = member.CLASS.title;

      // Recorta a string com a turma que o aluno pertence e retorna somente o número
      const classMember = classInitial.slice(6);


      const date = zonedTimeToUtc(member.due_date, 'America/Sao_Paulo');
      const due_date = format(date, "dd'/'MM'/'yyyy");

      return {
        id, name, modality, classMember, due_date,
      };
    }), [members]);

  const handleDelete = useCallback(async () => {
    try {
      await api.delete(`class/${class_id}`);

      addToast({
        type: 'sucess',
        title: 'Turma removida com sucesso',
      });

      history.push('/dashboard-classes');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Deu um erro quando tentei excluir essa turma. Tenta de novo, por favor.',
      });
    }
  }, []);

  return (
    <Container>
      <PageTitle>
        <ClassTitle>{class_name}</ClassTitle>
        <h2>{modality_name}</h2>
      </PageTitle>

      <AddButton>
        <Button onClick={handleDelete}>
          Excluir turma
        </Button>
      </AddButton>

      <Content>
        <ScaleLoader loading={isLoading} color="#FFFFFF" />
        {members.length !== 0 && !isLoading
          ? (
            <BoxTable>
              <Table
                columns={[
                  { title: 'NOME', field: 'name', cellStyle: { width: '90%' } },
                  { title: 'VENCIMENTO', field: 'due_date', cellStyle: { width: '10%' } },
                ]}
                data={runMembers}
                loader={isLoading}
              />
            </BoxTable>
          ) : (
            <>
            </>
          )}

        {members.length === 0 && !isLoading
          ? (
            <SearchContainer>
              <FiFrown />
              <SearchMessage>Parece que essa turma está vazia</SearchMessage>
            </SearchContainer>
          ) : (
            <>
            </>
          )}
      </Content>
    </Container>
  );
};


export default MembersbyClass;
