import styled from 'styled-components';

export const Container = styled.div`
padding: 0 15px;
margin-top: 35px;
align-items: center;
flex-direction: column;
max-width: 100vw;

.MuiPaper-root {
    background-color: #312E38 !important;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #FCFE38;
  }

`;
