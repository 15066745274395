import styled from 'styled-components';

export const Container = styled.main`
display: flex;
align-items: center;
flex-direction: column;
`;

export const Content = styled.div`
    width: 85%;
    margin: 30px 290px;

    @media (max-width: 500px) {
        width: 95%;
    }
`;

export const PageTitle = styled.div`
    display: flex;
    place-content: center;
`;


export const AddButton = styled.button`
    place-content: center;
    width: 57px;
    height: 57px;
    background: #28262E;
    border: 0;
    border-radius: 10px;
    margin-top: 15px;
    @media (max-width: 500px) {
        height: 50px;
    }
`;
