import styled from 'styled-components';
import { shade } from 'polished';


export const Container = styled.main`
align-items: center;
display: flex;
flex-direction: column;

    form {
        display: flex;
        margin-top: 20px;

        div {
            border-radius: 20px;
            font-weight: 400;

            border-radius: 0;
        }
}
`;

export const Content = styled.div`
    /* margin: 30px 90px; */
    display: flex;
    align-items: center;
    place-content: center;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 500px) {
        /* margin: 30px 0;
        width: 100%; */
    }
`;

export const BoxTable = styled.div`
    width: 80%;
    /* margin: 30px 290px; */

    @media (max-width: 500px) {
        width: 95%;
    }
`;

export const PageTitle = styled.div`
    display: flex;
    flex-direction: column;

    place-content: center;
    align-items: center;

    h2 {
        font-size: 20px;
        color: #FCFE38;
    }
`;

export const ClassTitle = styled.div`
    display: flex;
    flex-direction: column;

    place-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
`;

// *** GRID ***
export const SearchButton = styled.button`
    place-content: center;
    width: 57px;
    height: 57px;
    background: #131216;
    border: 0;
    border-radius: 0 7px 7px 0;

    @media (max-width: 500px) {
        height: 50px;
    }
`;

export const AddButton = styled.div`
    place-content: center;
    width: 30%;
    /* height: 57px; */
    margin-top: 10px;

    @media (max-width: 500px) {
        width: 80%;
    }
`;

export const ContainerHeaderGrid = styled.div`
    width: 100%;
    display: flex;
    border-radius: 2px;

    & + div {
        margin-top: 16px;
    }

    @media (max-width: 500px) {
        width: 85%;
    }
`;

export const HeaderGrid = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const ItemsContainer = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
`;

export const HeaderName = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 95%;
    text-align: center;

    @media (max-width: 500px) {
        width: 95%;
    }

    /* background: #000; */
`;

export const HeaderModality = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 300px;
    text-align: center;

    @media (max-width: 500px) {
        width: 100px;
    }

    /* background: #000; */
`;

export const HeaderDueDate = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: #B2B2B2;
    width: 95%;
    text-align: center;

    @media (max-width: 500px) {
        width: 95%;
    }

    /* background: #000; */
`;

export const MembersList = styled.div`
    width: 100%;
    display: flex;
    border: #4C4A52 solid 1px;

    :last-of-type {
        border-radius: 0 0 10px 10px;
    }

    &:nth-child(2) {
        border-radius: 10px 10px 0 0;
    }

    @media (max-width: 500px) {
        width: 85%;
    }
`;

export const Grid = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;

    :hover {
        cursor: pointer;
        background: #232129;
    }
`;

export const MemberName = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 100%;
    min-height: 45px; // Controla a altura de toda a linha
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    overflow: hidden;

    @media (max-width: 500px) {
        width: 95%;
        font-size: 12px;
        min-height: 30px; // Controla a altura de toda a linha
    }
`;

export const MemberModality = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
        width: 100px;
        font-size: 10px;
    }

    /* background: #000; */
`;

export const MemberDueDate = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
        width: 95%;
        font-size: 12px;
    }

    /* background: #000; */
`;

export const SearchContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        color: #666360;
        width: 40px;
        height: 40px;
    }
`;

export const SearchMessage = styled.text`
    font-size: 25px;
    font-weight: 600;
    color: #666360;
    text-align: center;
`;
