import styled from 'styled-components';
import { shade } from 'polished';

import backgroundImg from '../../../assets/mosaico.png';

export const Container = styled.main`
    background: url(${backgroundImg}) no-repeat center;
    background-size: cover;
    height: calc(100vh - 60px);
`;

export const Content = styled.div`
    margin: 64px auto;
    display: flex;


    place-content: center;

    flex-wrap: wrap;
`;

export const PageTitle = styled.div`
    display: flex;


    h1 {
        margin-top: 10px;
        /* background-color: rgba(0,0,0,0.5); */
        background-color: #000;
        padding: 0 16px;
        border-radius: 3px;

    }

    place-content: center;
`;

export const Description = styled.div`
    /* background-color: rgba(0,0,0,0.5); */
    background-color: #000;
    border-radius: 3px;
    border: 0px;
    padding: 0 16px;
    color: #fff;
    /* width: 200px; */
    font-weight: bold;
    /* font-size: 10px; */

    margin: 15px;

    align-items: center;
    text-align: center;
    justify-content: space-around;

    display: flex;
`;
