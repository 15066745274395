import styled from 'styled-components';

export const Container = styled.div`
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
        margin-bottom: 30px;
    }

    form {
        width: 540px;
        text-align: center;

        @media (max-width: 500px) {
            width: 340px;

            h1 {
                margin-top: 10px;
                font-size: 25px;
            }
        }
    }
`;

export const InputContainer = styled.div`
    display: flex;
    margin-bottom: 8px;

    @media (max-width: 500px) {}
`;
