import styled from 'styled-components';

export const Container = styled.main`
`;

export const Content = styled.div`
    margin: 64px auto;
    place-content: center;

    flex-wrap: wrap;
`;

export const DayContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`;

export const Day = styled.div`
    border-radius: 3px;
    background: #28262E;
    border: 0px;
    padding: 0 16px;
    color: #FCFE38;
    font-weight: bold;

    width: 95%;

    margin: 0 10px 15px;

    align-items: center;
    text-align: center;

`;

export const Description = styled.div`
    border-radius: 3px;
    border: #4C4A52 solid 1px;
    padding: 0 16px;
    color: #D1D1D1;
    height: 40px;

    width: 95%;

    margin: 0 10px 5px 10px;

    align-items: center;
    text-align: center;
    justify-content: center;

    display: flex;
    flex-direction: column;

    h1 {
        font-weight: 300;
        font-size: 16px;
    }

    :hover {
        cursor: pointer;
        background: #232129;
    }
`;

export const TitleClass = styled.h2`
    color: #D1D1D1;
    font-size: 18px;
    font-weight: bold;
    align-items: center;
    text-align: center;
    margin-right: 6px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Days = styled.div`
    color: #928D8D;
    font-size: 11px;
    align-items: center;
    text-align: center;
    margin-right: 6px;
`;
