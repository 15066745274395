import React, {
  useCallback, useRef, useState, useEffect, ChangeEvent,
} from 'react';
import {
  FiUser, FiCalendar, FiClock,
} from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {
  Container, Content, InputContainer, TagInput, SelectContainer,
} from './styles';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

interface FormData {
    name: string;
    contact: string;
    date: string;
    time: string;
}

interface Modalities {
    id: string;
    title: string | null;
}

const NewPerson: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [modalities, setModalities] = useState<Modalities[]>([]);
  const [selectModality, setSelectModality] = useState<Modalities>();

  useEffect(() => {
    api.get('/modality').then((response) => setModalities(response.data));
  }, [selectModality]);

  // Captura o ID e o TEXTO da modalidade selecionada e salva em um estado de objeto tipado
  const onChangeSelectModality = useCallback(async (event: ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    const title = event.target.children[event.target.selectedIndex].textContent;

    const union: Modalities = { id, title };
    setSelectModality(union);

    // setClassOpen(true); // Libera para que o usuário veja a lista de turmas da modalidade escolhida

    // const value = await api.get(`/class/${id}`);
    // setClassTeam(value.data); // Aqui contém todos os dados das turmas dessa modalidade específica
  }, []);

  // Captura o ID e o TEXTO da turma selecionada e salva em um estado de objeto tipado
  //   const onChangeSelectClass = useCallback(async (event: ChangeEvent<HTMLSelectElement>) => {
  //     const id = event.target.value;
  //     const title = event.target.children[event.target.selectedIndex].textContent;

  //     const union: ClassTeam = { id, title };

  //     setSelectClassTeam(union);
  //     console.log(selectClassTeam);
  //   }, [selectClassTeam]);


  const handleSubmit = useCallback(async (data: FormData) => {
    try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Obrigatório'),
          contact: Yup.string().required('Obrigatório'),
          date: Yup.string().required('Obrigatório'),
          time: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          contact,
          date,
          time,
        } = data;

        const formData = {
          name,
          time,
          contact,
          date,
          modality: selectModality?.title,
          modality_id: selectModality?.id,
          type: 'experimental',
        };

        await api.post('/waitexperimental', formData);

        history.push('/experimental-list');

        addToast({
          type: 'sucess',
          title: 'Sucesso',
          description: 'Essa pessoa já está cadastrada na lista de alunos experimentais.',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao realizar esse cadastro. Tenta de novo, por favor.',
      });
    }
  }, [addToast, history, selectModality]);

  return (
    <Container>
      <Content>
        <h1>Novo experimental</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            date: format(new Date(), 'yyyy-MM-dd'),
            time: format(new Date(), 'HH:mm'),
          }}
        >
          <InputContainer>
            <TagInput>Nome: </TagInput>
            <Input name="name" icon={FiUser} />
          </InputContainer>

          <InputContainer>
            <TagInput>Contato: </TagInput>
            <Input name="contact" icon={FiUser} />
          </InputContainer>

          <InputContainer>
            <TagInput>Modalidade: </TagInput>
            <SelectContainer>
              <select name="modality_id" id="modality" onChange={onChangeSelectModality}>
                <option value="0">Selecione uma modalidade</option>
                {modalities.map((modality) => (
                  <option key={modality.id} value={modality.id}>{modality.title}</option>
                ))}
              </select>
            </SelectContainer>
          </InputContainer>

          <InputContainer>
            <TagInput>Data: </TagInput>
            <Input name="date" type="date" icon={FiCalendar} />
          </InputContainer>

          <InputContainer>
            <TagInput>Horário: </TagInput>
            <Input name="time" type="time" icon={FiClock} />
          </InputContainer>


          <Button type="submit">Cadastrar</Button>

        </Form>
      </Content>
    </Container>
  );
};


export default NewPerson;
