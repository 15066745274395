import React, {
  useState, useCallback, useEffect, forwardRef, useRef,
} from 'react';

import {
  FiUser, FiClock, FiBookmark, FiFrown,
} from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import ScaleLoader from 'react-spinners/ScaleLoader';

import {
  Container, Content, Description, Day, TitleClass, Time, DayContainer, ListTitle, ItemsCardContainer, SearchContainer, SearchMessage,
} from './styles';

import api from '../../../../../services/api';

import DialogBox from '../../../../../components/DialogBox';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';

import { useToast } from '../../../../../hooks/toast';

interface ClassList {
    modalityId: string;
    modalityName: string;
}

interface Person {
    id: string;
    name: string;
    contact: string;
    created_at?: string;

    modality_reference: {
        title: string;
    };
    date: string;
    time: string;
}

const DashboardWaitingList = forwardRef(
  // ATIVAR MÉTODO FILHO: passo 7 (fazer o recebimento dessa referência)
  (props: ClassList) => {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);

    const [persons, setPersons] = useState<Person[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<Person>();
    const [boxIsOpen, setBoxIsOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const loadPanel = useCallback(() => {
      try {
        api.get(`/waitexperimental/waiting/${props.modalityId}`).then((response) => {
          setPersons(response.data);
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ;(',
          description: 'Ocorreu um erro ao carregar a lista de alunos. Por favor, saia da página e retorne para uma nova tentativa.',
        });
      }
    }, [addToast, props.modalityId]);

    useEffect(() => {
      loadPanel();
    }, [loadPanel]);

    const handleDelete = useCallback(async (id: string | undefined) => {
      try {
        await api.delete(`waitexperimental/${id}`);

        loadPanel();

        setBoxIsOpen(false);

        addToast({
          type: 'sucess',
          title: 'Aluno removido com sucesso',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ;(',
          description: 'Deu um erro quando tentei excluir esse aluno. Tenta de novo, por favor.',
        });
      }
    }, [addToast, loadPanel]);

    const handleSelectPerson = useCallback(async (id: string, name: string, contact: string, modality: string, date: string, time: string) => {
      const values = {
        id, name, contact, modality_reference: { title: modality }, date, time,
      };

      setSelectedPerson(values);

      setBoxIsOpen(true);
    }, []);

    return (
      <Container>
        <Content>
          <DayContainer>
            <Day>
              <h1>{props.modalityName}</h1>
            </Day>


            {isLoading ? <ScaleLoader loading={isLoading} color="#FFFFFF" />
              : (
                <>
                  {persons.map((person) => (
                    <Description
                      onClick={() => { handleSelectPerson(person.id, person.name, person.contact, person.modality_reference.title, person.date, person.time); }}
                    >
                      <TitleClass>
                        {person.name}
                      </TitleClass>

                      <Time>
                        {person.created_at}
                      </Time>
                    </Description>
                  ))}
                </>
              )}

            {persons.length === 0 && !isLoading ? (
              <>
                <SearchContainer>
                  <FiFrown />
                  <SearchMessage>Nenhum aluno em espera</SearchMessage>
                </SearchContainer>
              </>
            ) : (
              <></>
            )}

          </DayContainer>

          <DialogBox
            open={boxIsOpen}
            onClose={() => setBoxIsOpen(false)}
          >
            <ListTitle style={{ fontSize: 25 }}>Aguardando vaga</ListTitle>
            <Form
              ref={formRef}
              onSubmit={() => {}}
              initialData={{
                name: selectedPerson?.name,
                contact: selectedPerson?.contact,
                modality: selectedPerson?.modality_reference.title,
                date: selectedPerson?.date,
                time: selectedPerson?.time,

              }}
            >
              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input name="name" disabled icon={FiUser} />
              </ItemsCardContainer>

              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input name="modality" disabled icon={FiBookmark} />
              </ItemsCardContainer>

              <ItemsCardContainer style={{ marginBottom: 5 }}>
                <Input type="time" name="time" disabled icon={FiClock} />
              </ItemsCardContainer>

              <Button
                style={{ width: '100%' }}
                onClick={() => { handleDelete(selectedPerson?.id); }}
              >
                Excluir

              </Button>
            </Form>
          </DialogBox>
        </Content>
      </Container>
    );
  },
);


export default DashboardWaitingList;
