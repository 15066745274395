import styled from 'styled-components';

export const Container = styled.div`
    width: 500px;
    max-width: 95%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background: #28262E;
    padding: 10px;
    border-radius: 4px;



    :hover {
        cursor: auto;
    }

    > div {
        display: flex;
        align-items: center;
        place-content: center;
        flex-direction: column;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    z-index: 999;
`;
