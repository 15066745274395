import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { FiPlus, FiFrown } from 'react-icons/fi';

import ScaleLoader from 'react-spinners/ScaleLoader';

import {
  Container, Content, PageTitle, Day, DayContainer, CardsContainer, Card, ItemsCardContainer, DateLabel, DateValue, OthersLabel, OthersValue, Actions, AddButton, SearchContainer, SearchMessage,
} from './styles';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

interface Teach {
    id: string;
    modality: string;
    modality_id: string;
    date: string;
    time: string;
    coach: string;
    space: string;
    occupied_spaces: string;
    type: string;
}

const Events: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { user } = useAuth();

  const [teachs, setTeachs] = useState<Teach[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const loadPanel = useCallback(() => {
    try {
      api.get('/teach/checkin-events').then((response) => {
        setTeachs(response.data);
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Ocorreu um erro ao carregar os eventos. Por favor, saia da página e retorne para uma nova tentativa.',
      });
    }
  }, [addToast]);

  useEffect(() => {
    loadPanel();
  }, [loadPanel]);

  const formattedTeachs = useMemo(() => teachs
    .map((item) => {
      const formattedDate = zonedTimeToUtc(item.date, 'America/Sao_Paulo');
      const date = format(formattedDate, "dd'/'MM'/'yyyy");
      const dateISO = format(formattedDate, "yyyy'-'MM'-'dd");
      const { id } = item;
      const { modality } = item;
      const { modality_id } = item;
      const { time } = item;
      const { coach } = item;
      const { space } = item;
      const { occupied_spaces } = item;

      return {
        date, dateISO, id, modality, modality_id, time, coach, space, occupied_spaces,
      };
    }), [teachs]);

  return (
    <Container>
      <PageTitle>
        <h1>Eventos</h1>
      </PageTitle>

      {user.permission === 'member' ? (
        <>
        </>
      ) : (
        <Actions>
          <AddButton>
            <FiPlus
              onClick={() => history.push('/new-event')}
            />
          </AddButton>
        </Actions>
      )}

      <Content>
        <DayContainer>
          <ScaleLoader loading={isLoading} color="#FFFFFF" />
          {formattedTeachs.length !== 0 && !isLoading ? (
            <>
              {formattedTeachs.map((item) => (
                <>
                  <Day>
                    <h1>{item.modality}</h1>
                  </Day>
                  <CardsContainer
                    key={item.id}
                  >
                    <Card
                      onClick={() => {
                        history.push({
                          pathname: '/events-check',
                          state: {
                            id: item.id, title: item.modality, date: item.date, dateISO: item.dateISO, time: item.time, space: item.space, modality_id: item.modality_id,
                          },
                        });
                      }}
                    >
                      <ItemsCardContainer>
                        <DateLabel>Data:</DateLabel>

                        <DateValue>{item.date}</DateValue>
                      </ItemsCardContainer>

                      <ItemsCardContainer>
                        <OthersLabel>Horário:</OthersLabel>

                        <OthersValue>{item.time}</OthersValue>
                      </ItemsCardContainer>

                      <ItemsCardContainer>
                        <OthersLabel>Confirmados:</OthersLabel>

                        <OthersValue>
                          {item.occupied_spaces}
                          /
                          {item.space}
                        </OthersValue>
                      </ItemsCardContainer>
                    </Card>
                  </CardsContainer>
                </>
              ))}
            </>
          ) : (
            <>
            </>
          )}

          {formattedTeachs.length === 0 && !isLoading ? (
            <SearchContainer>
              <FiFrown />
              <SearchMessage>Nenhum evento encontrado</SearchMessage>
            </SearchContainer>
          ) : (
            <>

            </>
          )}
        </DayContainer>
      </Content>
    </Container>
  );
};


export default Events;
