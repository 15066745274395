import styled from 'styled-components';

export const Container = styled.div`
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
        margin-bottom: 30px;
    }

    form {
        width: 540px;
        text-align: center;

        @media (max-width: 500px) {
            width: 340px;

            h1 {
                margin-top: 10px;
                font-size: 25px;
            }
        }
    }
`;

export const ContentForm = styled.div`
    form {
        width: 400px;
        text-align: center;

        @media (max-width: 500px) {
            width: 340px;

            h1 {
                margin-top: 10px;
                font-size: 25px;
            }
        }
    }
`;

export const InputContainer = styled.div`
    display: flex;
    margin-bottom: 8px;

    @media (max-width: 500px) {}
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ActionsBox = styled.div`
    display: flex;
    width: 80%;
    height: 45px;
    border-radius: 3px;
    margin-bottom: 15px;
    justify-content: space-around;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;

        :hover {
            cursor: pointer;
        }
    }
`;

export const ActionsCircle = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    justify-content: space-around;
    align-items: center;
    background: #FCFE38;
    color: #312e38;

    :hover {
        transform: translateY(-5px);
        transition: all .2s;
    }
`;
