import React, {
  useState, useEffect,
} from 'react';

import {
  Container, Content, PageTitle,
} from './styles';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import CheckInList from '../CheckIn List';
import { DialogPayment } from '../../../components/DialogPayment';

interface Modality {
    modality: string;
    modality_id: string;
}

const CheckInMain: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [modalities, setModalities] = useState<Modality[]>([]);

  useEffect(() => {
    try {
      setModalities(JSON.parse(user.modalities));
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ;(',
        description: 'Ocorreu um erro ao carregar a lista de modalidades. Por favor, saia da página e retorne para uma nova tentativa.',
      });
    }
  }, [addToast, user.modalities]);

  return (
    <Container>

      <PageTitle>
        <h1>Aulas</h1>
      </PageTitle>

      <Content>
        {modalities.map((modality) => (
          <CheckInList
            key={modality.modality_id}
            modalityId={modality.modality_id}
            modalityName={modality.modality}
          />
        ))}
      </Content>
      <DialogPayment />
    </Container>
  );
};


export default CheckInMain;
