/* eslint-disable array-callback-return */
import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  FiCalendar, FiClock, FiCheck, FiFrown, FiUser, FiLock,
} from 'react-icons/fi';

import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Container, Content, ActionsContainer, Button, CardsContainer, CardsBox, Card, ItemsCardContainer, EventTitle, DateValue, ListTitle, ConfirmedContainer, ConfirmedBox, ConfirmedContent, SearchContainer, SearchMessage, CoachValue,
} from './styles';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import DialogBox from '../../../components/DialogBox';


import api from '../../../services/api';

interface UpdateTeachFormData {
    date: Date;
    time: string;
    coach: string;
    space: string;
}

interface Confirmations {
    id: string;
    user_id: string;
    name: string;
}

interface LocationProps {
    id: string;
    title: string;
    modality_id: string;
    date: Date;
    dateISO: string;
    time: string;
    coach: string;
    space: string;
    occupied_spaces: string;
    type: string;
}

const TeachCheck: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation<LocationProps>();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmations, setConfirmations] = useState<Confirmations[]>([]);

  const [boxIsOpen, setBoxIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.get(`/checkin/${location.state.id}`).then((response) => {
      setConfirmations(response.data);

      response.data.filter(({ user_id }: any) => user_id === user.id)
        .map(() => {
          setIsConfirmed(true);
        });
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [location.state.id, user.id]);

  const handleConfirm = useCallback(async () => {
    try {
      const formData = {
        teach_id: location.state.id,
        modality_id: location.state.modality_id,
        user_id: user.id,
      };

      await api.post('/checkin', formData);

      addToast({
        type: 'sucess',
        title: 'Sucesso',
        description: 'O seu check-in tá feito ;D',
      });

      history.push('/check-in');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Xi',
        // description: 'Pelo jeito, a última vaga dessa aula acabou de ser preenchida ou seu plano está vencido.',
        description: 'data' in error.response ? error.response.data.message : 'Ocorreu um erro ao fazer o check-in. Tenta de novo, por favor',

      });
      history.push('/check-in');
    }
  }, [addToast, location.state.id, location.state.modality_id, user.id, history]);

  const handleCancelConfirmation = useCallback(async () => {
    try {
      await api.delete('/checkin', { params: { teach_id: location.state.id, user_id: user.id } });

      addToast({
        type: 'sucess',
        title: 'Sucesso',
        description: 'O seu check-in foi cancelado ;D',
      });

      history.push('/check-in');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Xi',
        description: 'Pelo jeito, deu algum problema no cancelamento do check-in. Tenta de novo, por favor.',

      });
      history.push('/check-in');
    }
  }, [addToast, location.state.id, user.id, history]);

  const handleDelete = useCallback(async () => {
    try {
      await api.delete(`/teach/${location.state.id}`);

      addToast({
        type: 'sucess',
        title: 'Sucesso',
        description: 'A aula já foi excluída ;D',
      });

      history.push('/teach-groups');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Xi',
        description: 'Deu algum erro na hora de excluir essa aula. Tenta de novo, por favor ;(',
      });
    }
  }, [addToast, location.state.id, history]);

  const handleSubmit = useCallback(async (data: UpdateTeachFormData) => {
    try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          date: Yup.date().required('Obrigatório'),
          time: Yup.string().required('Obrigatório'),
          coach: Yup.string().required('Obrigatório'),
          space: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          date,
          time,
          coach,
          space,
        } = data;

        const formData = {
          id: location.state.id,
          date,
          time,
          coach,
          space,
        };

        await api.put('/teach', formData);

        history.push('/teach-groups');

        addToast({
          type: 'sucess',
          title: 'Aula editada com sucesso',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'info',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao realizar essa edição. Tenta de novo, por favor.',
      });
    }
  }, [addToast, history, location.state.id]);

  return (
    <Container>
      <Content>
        <CardsContainer>
          <EventTitle>
            {location.state.title}
          </EventTitle>
          <CardsBox>

            <Card>
              <FiUser />
              <CoachValue>{location.state.coach}</CoachValue>
            </Card>

            <Card>
              <FiCalendar />
              <DateValue>{location.state.date}</DateValue>
            </Card>

            <Card>
              <FiClock />
              <DateValue>{location.state.time}</DateValue>
            </Card>


          </CardsBox>

          {user.permission === 'coach' ? (
            <ActionsContainer>
              <Button
                onClick={() => setBoxIsOpen(true)}
              >
                Editar

              </Button>

              <Button
                onClick={handleDelete}
              >
                Excluir

              </Button>
            </ActionsContainer>
          ) : (
            <>
              {isConfirmed ? (
                <Button
                  onClick={handleCancelConfirmation}
                >
                  Cancelar presença

                </Button>

              ) : (
                <Button
                  onClick={handleConfirm}
                >
                  Confirmar presença

                </Button>
              )}
            </>
          )}

        </CardsContainer>

        <ListTitle>Presenças confirmadas</ListTitle>

        <ScaleLoader loading={isLoading} color="#FFFFFF" />

        {confirmations.length !== 0 && !isLoading ? (
          <ConfirmedContainer>
            {confirmations.map((item) => (
              <ConfirmedBox
                key={item.id}
              >
                <FiCheck />

                <ConfirmedContent>
                  {item.name}
                  <h2>Confirmado(a)</h2>
                </ConfirmedContent>
              </ConfirmedBox>

            ))}

          </ConfirmedContainer>
        ) : (
          <></>
        )}

        {confirmations.length === 0 && !isLoading ? (
          <SearchContainer>
            <FiFrown />
            <SearchMessage>Nenhuma presença confirmada até agora</SearchMessage>
          </SearchContainer>
        ) : (
          <></>
        )}

        <DialogBox
          open={boxIsOpen}
          onClose={() => setBoxIsOpen(false)}
        >
          <ListTitle style={{ fontSize: 25 }}>Atualizar aula</ListTitle>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              coach: location.state.coach,
              space: location.state.space,
              date: location.state.dateISO,
              time: location.state.time,
            }}
          >
            <ItemsCardContainer style={{ marginBottom: 5 }}>
              <Input type="date" name="date" icon={FiCalendar} />
            </ItemsCardContainer>

            <ItemsCardContainer style={{ marginBottom: 5 }}>
              <Input type="time" name="time" icon={FiClock} />
            </ItemsCardContainer>

            <ItemsCardContainer style={{ marginBottom: 5 }}>
              <Input name="coach" icon={FiUser} />
            </ItemsCardContainer>

            <ItemsCardContainer style={{ marginBottom: 5 }}>
              <Input type="number" name="space" icon={FiLock} />
            </ItemsCardContainer>

            <Button
              style={{ width: '100%' }}
              type="submit"
            >
              Atualizar

            </Button>
          </Form>
        </DialogBox>
      </Content>
    </Container>
  );
};


export default TeachCheck;
