import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import CoachRoute from './CoachRoute';

// STANDARD
import SignIn from '../pages/01 - Standard/SignIn/Index';
import SignUp from '../pages/06 - Coaches/Members/SignUp/Index';

// STANDARD
import ForgotPassword from '../pages/02 - User/ForgotPassword/Index';
import ResetPassword from '../pages/02 - User/ResetPassword/Index';
import Profile from '../pages/02 - User/Profile/Index';

// HOMES
import HomeMembers from '../pages/03 - Home Members/index';
import RecurringCredit from '../pages/03 - RecurringCredit/index';
import RecurringCreditSuccess from '../pages/03 - RecurringCreditSuccess/index';

// INFO PAGES
import Mission from '../pages/05 - Informational Pages/Mission/index';
import WhoWeAre from '../pages/05 - Informational Pages/Who We Are/index';
import Timesheet from '../pages/05 - Informational Pages/Timesheet/index';
import Partnerships from '../pages/05 - Informational Pages/Partnerships/index';
import Contracts from '../pages/05 - Informational Pages/Contracts/index';

// PROFESSORES
import DashboardMembers from '../pages/06 - Coaches/Members/Dashboard Members/index';
import NewMember from '../pages/06 - Coaches/Members/New Member/Index';
import UpdateMember from '../pages/06 - Coaches/Members/Update Member/Index';

import DashboardClasses from '../pages/06 - Coaches/Classes/Dashboard Classes/Dashboard Classes Main/index';
import NewClass from '../pages/06 - Coaches/Classes/New Class/Index';
import MembersbyClass from '../pages/06 - Coaches/Members/Members by Class/index';

import Birthdays from '../pages/06 - Coaches/Members/Birthdays/index';

import DashboardWaitingList from '../pages/06 - Coaches/Waiting List/Dashboard Waiting List/Dashboard Waiting List Main';
import NewPerson from '../pages/06 - Coaches/Waiting List/New Person/Index';

import DashboardExperimentalList from '../pages/06 - Coaches/Experimental List/Dashboard Experimental List/Dashboard Experimental Main';
import NewPersonExperimental from '../pages/06 - Coaches/Experimental List/New Person/Index';

import DashboardMembersRegistered from '../pages/06 - Coaches/Members Registered/Dashboard Members Registered/index';

import DashboardTeachGroupsMain from '../pages/06 - Coaches/Teach Groups/Dashboard Teach Groups/Dashboard Teach Groups Main';
import NewTeachGroups from '../pages/06 - Coaches/Teach Groups/New Teach Groups/Index';

import CheckInMain from '../pages/07 - CheckIn/CheckIn Main';
import TeachCheck from '../pages/07 - CheckIn/Teach Check';

import NewEvent from '../pages/06 - Coaches/Events/New Event/Index';
import Events from '../pages/08 - Events/index';
import EventsCheck from '../pages/09 - Events Check/index';

import DashboardWods from '../pages/06 - Coaches/Wod/Dashboard Wods';
import NewWod from '../pages/06 - Coaches/Wod/New Wod/Index';
import UpdateWod from '../pages/06 - Coaches/Wod/Update Wod/Index';

import MyWods from '../pages/Wod/Dashboard Wods';
import DetailsWod from '../pages/Wod/Details Wod/Index';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />

    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/profile" component={Profile} isPrivate />

    <Route path="/home" component={HomeMembers} isPrivate />
    <Route path="/recurring-credit" component={RecurringCredit} isPrivate />
    <Route path="/recurring-credit-success" component={RecurringCreditSuccess} isPrivate />

    <Route path="/mission" component={Mission} isPrivate />
    <Route path="/who-we-are" component={WhoWeAre} isPrivate />
    <Route path="/timesheet" component={Timesheet} isPrivate />
    <Route path="/partnerships" component={Partnerships} isPrivate />
    <Route path="/contracts" component={Contracts} isPrivate />

    <Route path="/birthdays" component={Birthdays} isPrivate />

    <Route path="/teach-groups" component={DashboardTeachGroupsMain} isPrivate />

    <Route path="/check-in" component={CheckInMain} isPrivate />
    <Route path="/teach-check" component={TeachCheck} isPrivate />

    <Route path="/events" component={Events} isPrivate />
    <Route path="/events-check" component={EventsCheck} isPrivate />

    <Route path="/my-wods" component={MyWods} isPrivate />
    <Route path="/details-wod" component={DetailsWod} isPrivate />

    {/* Rotas exclusivas de professores */}

    <CoachRoute path="/wods" component={DashboardWods} isPrivate />
    <CoachRoute path="/new-wod" component={NewWod} isPrivate />
    <CoachRoute path="/update-wod" component={UpdateWod} isPrivate />

    <CoachRoute path="/new-event" component={NewEvent} isPrivate />

    <CoachRoute path="/new-teach-groups" component={NewTeachGroups} isPrivate />

    <CoachRoute path="/waiting-list" component={DashboardWaitingList} isPrivate />
    <CoachRoute path="/new-person" component={NewPerson} isPrivate />

    <CoachRoute path="/experimental-list" component={DashboardExperimentalList} isPrivate />
    <CoachRoute path="/new-person-experimental" component={NewPersonExperimental} isPrivate />

    <CoachRoute path="/dashboard-members-registered" component={DashboardMembersRegistered} isPrivate />

    <CoachRoute path="/dashboard-members" component={DashboardMembers} isPrivate />
    <CoachRoute path="/new-member" component={NewMember} isPrivate />
    <CoachRoute path="/update-member" component={UpdateMember} isPrivate />

    <CoachRoute path="/dashboard-classes" component={DashboardClasses} isPrivate />
    <CoachRoute path="/new-class" component={NewClass} isPrivate />
    <CoachRoute path="/members-by-class" component={MembersbyClass} isPrivate />

  </Switch>
);

export default Routes;
